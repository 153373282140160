import React from "react";
import { Box, Link, Stack } from "@mui/material";
import { Text as ClipboardText } from "@clipboard-health/ui-react";
import { DNR_INCIDENT_REPORT_SUPPORT_LINK } from "./dnrFormConstants";

interface DnrFeedbackTextProps {
  shouldShowUpdatedDNRCopy: boolean;
}

/**
 * Component for displaying the DNR feedback text instructions
 */
export const DnrFeedbackText: React.FC<DnrFeedbackTextProps> = ({ shouldShowUpdatedDNRCopy }) => {
  if (shouldShowUpdatedDNRCopy) {
    return (
      <Stack spacing={1}>
        <ClipboardText variant="body2" sx={{ fontStyle: "italic", fontSize: "0.875rem" }}>
          In the event of a safety incident, our Trust & Safety department would like to work with
          you to launch an investigation.
        </ClipboardText>
        <ClipboardText variant="body2" sx={{ fontStyle: "italic", fontSize: "0.875rem" }}>
          Click{" "}
          <Link href={DNR_INCIDENT_REPORT_SUPPORT_LINK} target="_blank">
            this link
          </Link>{" "}
          to complete a safety incident report form. A T&S team member will contact you to conduct a
          formal investigation and rectify the situation.
        </ClipboardText>
        <ClipboardText variant="body2" sx={{ fontSize: "0.875rem" }}>
          Please share specific details on why you&apos;re DNRing this professional. More
          information helps us take appropriate action.
        </ClipboardText>
        <ClipboardText variant="body2" sx={{ fontSize: "0.875rem" }}>
          Your input is not shared with the professional.
        </ClipboardText>
      </Stack>
    );
  }

  return (
    <Box sx={{ fontSize: "0.875rem" }}>
      Please provide any additional details about the DNR to help us take the appropriate action.
      Note that your response is anonymous and for Clipboard&apos;s internal use only.
    </Box>
  );
};
