import { Text } from "@clipboard-health/ui-react";
import { Box, Card, CardContent, Stack, SxProps, Theme } from "@mui/material";
import { type ReactNode } from "react";

interface CbhCustomChatMessageProps {
  message: string;
  children?: ReactNode;
  avatarSxProps?: SxProps<Theme>;
  messageContainerSxProps?: SxProps<Theme>;
}

export function CbhCustomChatMessage(props: CbhCustomChatMessageProps) {
  const { message, children, avatarSxProps, messageContainerSxProps } = props;

  return (
    <Stack direction="row" spacing={1} marginBottom={2} sx={{ ...messageContainerSxProps }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          ...avatarSxProps,
        }}
      >
        <img src="/bunnyB.png" alt="" width={32} height={32} />
      </Box>
      <Stack>
        <Text variant="caption">Clipboard Health</Text>
        <Card
          sx={{
            backgroundColor: (theme) => theme.palette.grey[300],
            boxShadow: "none",
          }}
        >
          <CardContent
            sx={{
              padding: 1,
              "&:last-child": {
                paddingBottom: 1,
              },
            }}
          >
            <Text
              sx={{
                fontSize: 14,
                whiteSpace: "pre-wrap",
              }}
            >
              {message}
            </Text>
          </CardContent>
          {children}
        </Card>
      </Stack>
    </Stack>
  );
}
