import { useInfiniteQuery, UseInfiniteQueryOptions } from "@tanstack/react-query";
import { environmentConfig } from "@src/appV2/environment";
import { z } from "zod";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { get } from "@src/appV2/api";
import { isEmpty } from "lodash";
import { isDefined } from "@clipboard-health/util-ts";

const searchAgentsParamsSchema = z.object({
  searchInput: z.string().nullish(),
  limit: z.number().optional(),
});

export const agentSchema = z.object({
  _id: z.string(),
  userId: z.string(),
  name: z.string(),
  email: z.string(),
  phone: z.string(),
  highlights: z.array(z.string()),
  stage: z.string(),
  qualification: z.string().nullish(),
});

const searchAgentsResponseSchema = z.object({
  list: z.array(agentSchema),
  count: z.number().nullish(),
});

type UseSearchAgentsParams = z.infer<typeof searchAgentsParamsSchema>;
type SearchAgentsResponse = z.infer<typeof searchAgentsResponseSchema>;
export type Agent = z.infer<typeof agentSchema>;

const DEFAULT_PAGE_SIZE = 15;

export function useSearchAgents(
  params: UseSearchAgentsParams,
  options?: UseInfiniteQueryOptions<SearchAgentsResponse>
) {
  const { limit = DEFAULT_PAGE_SIZE, searchInput } = params;
  const url = `${environmentConfig.REACT_APP_BASE_API_URL}/user/agentSearch`;

  return useInfiniteQuery({
    queryKey: [url, searchInput?.trim().toLowerCase(), limit],
    queryFn: async (context) => {
      const { pageParam: skip = 0 } = context;
      const response = await get({
        url,
        queryParams: {
          skip,
          limit,
          searchInput: searchInput ?? "",
        },
        responseSchema: searchAgentsResponseSchema,
      });
      return response.data;
    },
    getNextPageParam: (_lastPage, allPages) => {
      const totalFetched = allPages.flatMap((page) => page.list).length;
      const count = allPages[0].count; // Count only exists in the first page response
      if (count && totalFetched < count) {
        return totalFetched; // return the new "skip" value for the next page
      }
      return undefined;
    },
    enabled: isDefined(searchInput) && !isEmpty(searchInput) && (options?.enabled ?? true),
    staleTime: 60 * 1000,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.SEARCH_AGENTS_FAILURE,
    },
    ...options,
  });
}
