/**
 * Constants used in the DNR submission form
 */

export const DNR_INCIDENT_REPORT_SUPPORT_LINK =
  "https://support.clipboardhealth.com/hc/en-us/requests/new?ticket_form_id=9293203444247";

/**
 * Creates a feedback required message with the minimum length
 */
export const createFeedbackRequiredMessage = (minLength: number): string =>
  `Please enter at least ${minLength} characters. Your detailed feedback will help us\ntake the appropriate action to maintain the standards of the platform.`;
