import { Badge, Tag } from "antd";
import moment from "moment-timezone";
import { ReactNode } from "react";
import { Shift } from "src/interface";
import { FULL_ZONE_NAMES } from "../constants/timezone";
import { getOffsetFormattedTextBetweenTimezones } from "../utils/datetime";
import { getWorkerShiftTypeLabel } from "../utils/worker";

const lightBlue = "#f1f8ff";
const amColor = "#57afef";
const pmColor = "#0c71ba";
const nocColor = "#375f7d";
const cancelColor = "grey";
const dailyPayColor = "#ddd622";
const fullPaidColor = "#94C95F";
const partiallyPaidColor = "#1890FF";

function getShiftTagStyle(name: string, working?: boolean, signed = false) {
  const style = {
    padding: "0px 4px 0px 4px",
    fontSize: "0.75em",
  };

  if (signed) {
    return { ...style };
  }

  switch (name.toLowerCase()) {
    case "am":
      if (working) {
        return {
          ...style,
          backgroundColor: lightBlue,
          color: amColor,
          borderColor: amColor,
        };
      }
      return {
        ...style,
        backgroundColor: amColor,
        color: "#fff",
        borderColor: amColor,
      };
    case "pm":
      if (working) {
        return {
          ...style,
          backgroundColor: lightBlue,
          color: pmColor,
          borderColor: pmColor,
        };
      }
      return {
        ...style,
        backgroundColor: pmColor,
        color: "#fff",
        borderColor: pmColor,
      };
    case "noc":
      if (working) {
        return {
          ...style,
          backgroundColor: lightBlue,
          color: nocColor,
          borderColor: nocColor,
        };
      }
      return {
        ...style,
        backgroundColor: nocColor,
        color: "#fff",
        borderColor: nocColor,
      };
    default:
      return { backgroundColor: amColor };
  }
}

export function getShiftTagColor(name: string) {
  switch ((name || "").toLowerCase()) {
    case "am":
      return amColor;
    case "pm":
      return pmColor;
    case "noc":
      return nocColor;
    case "cancel":
      return cancelColor;
    case "daily-pay":
      return dailyPayColor;
    case "full paid":
      return fullPaidColor;
    case "partially paid":
      return partiallyPaidColor;
    case "not yet paid":
      return cancelColor;
    default:
      return "#57afef";
  }
}

/**
 * FIXME - split this code into separate files - one component per file
 */
interface ShiftTagProps {
  working?: boolean;
  shift: string;
  size?: number;
  text?: string;
  open?: boolean;
  signed?: boolean;
}

export function ShiftTag(props: ShiftTagProps) {
  const { working, shift, size, text, open, signed } = props;
  let body: ReactNode | string = text || shift.toUpperCase();
  if (open) {
    body += " Open";
    return <Tag style={getShiftTagStyle(shift, working)}>{body}</Tag>;
  }

  if (working) {
    body = `Working ${shift.toUpperCase()}`;
    if (size && size <= 1) {
      body = (
        <div>
          <img style={{ height: 15, marginRight: 4 }} src="/bunnyB.png" alt="img" />
          {shift.toUpperCase()}
        </div>
      );
    }
  }

  return (
    <Tag
      style={getShiftTagStyle(shift, working, signed)}
      color={signed ? "gold" : undefined}
      test-id={`shift-slot-${shift}`}
    >
      {body}
    </Tag>
  );
}

/**
 * FIXME - split this code into separate files - one component per file
 */
interface ShiftTitleProps {
  shift: Shift;
  hasUnreadShifts?: boolean;
  isAgent?: boolean;
  isFacility?: boolean;
}

export function ShiftTitle(props: ShiftTitleProps) {
  const { shift, hasUnreadShifts, isAgent, isFacility } = props;
  moment.fn.zoneName = function () {
    const abbr = this.zoneAbbr();
    return FULL_ZONE_NAMES[abbr] || abbr;
  };
  let shiftStart = moment(shift.start);
  let shiftEnd = moment(shift.end);
  const timeFormat = "hh:mm a";
  const { tmz: facilityTimezone } = shift.facility!;
  const { tmz: agentTimezone } = shift.agent || {};
  if (facilityTimezone) {
    shiftStart = moment(shift.start).tz(facilityTimezone);
    shiftEnd = moment(shift.end).tz(facilityTimezone);
  }
  const startAndEndInDifferentTimezone = shiftStart.zoneAbbr() !== shiftEnd.zoneAbbr();
  const rangeText = `${shiftStart.format(`${timeFormat} z`)} until ${shiftEnd.format(
    `${isFacility && startAndEndInDifferentTimezone ? `${timeFormat} z,` : timeFormat}`
  )}`;

  const durationInHours = shiftEnd.diff(shiftStart, "hour", true);
  return (
    <div>
      {hasUnreadShifts && <Badge status="success" />}
      <ShiftTag shift={shift.name!} />
      <Tag>{getWorkerShiftTypeLabel(shift.agentReq)}</Tag>
      {shiftStart.calendar(null, {
        sameElse: "ddd MMM Do YYYY",
      })}
      &nbsp;
      {!shift.deleted && (
        <i>
          ({durationInHours?.toFixed(2)} hrs, {rangeText}{" "}
          {shiftEnd.date() !== shiftStart.date() ? "next day" : ""}{" "}
          {isFacility &&
            startAndEndInDifferentTimezone &&
            "with a clock change for Daylight Saving Time"}
          )
        </i>
      )}
      {isAgent &&
        agentTimezone &&
        facilityTimezone &&
        moment(shift.start).tz(agentTimezone).utcOffset() !==
          moment(shift.start).tz(facilityTimezone).utcOffset() &&
        ` - ${moment().tz(facilityTimezone).zoneName()} (${getOffsetFormattedTextBetweenTimezones(
          shift.start,
          agentTimezone,
          facilityTimezone
        )})`}
      {shift.deleted && (
        <i>{shift.isBillable && "(Late Cancel: $" + shift.charge! * shift.time! + " Fee)"}</i>
      )}
    </div>
  );
}
