import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";
import { DEFAULT_DNR_REASONS } from "./constants";

/**
 * Hook to get DNR reasons from feature flag with fallback to default reasons
 * @returns DNR configuration with reasons
 */
export const useDnrConfig = () => {
  return useCbhFlag(CbhFeatureFlag.DNR_CONFIG, {
    defaultValue: DEFAULT_DNR_REASONS,
  });
};
