export enum DeploymentEnvironmentName {
  LOCAL = "local",
  DEVELOPMENT = "development",
  STAGING = "staging",
  PROD_SHADOW = "prod-shadow",
  PROD_RECREATED = "prod-recreated",
  PRODUCTION = "production",
}

export enum NodeEnvironment {
  DEVELOPMENT = "development",
  PRODUCTION = "production",
  TEST = "test",
}
