import { SxProps } from "@mui/material";

// Common styles for dropdowns
export const selectStyles: SxProps = {
  fontSize: ".875rem",
  height: "auto",
  "& .MuiSelect-select": {
    padding: "8px 14px",
  },
  "& .MuiOutlinedInput-notchedOutline legend": {
    width: "0px",
    height: "0px",
    overflow: "hidden",
    visibility: "hidden",
    padding: 0,
    margin: 0,
  },
};

export const menuItemStyles: SxProps = {
  fontSize: ".875rem",
};

export const textFieldStyles: SxProps = {
  "& .MuiOutlinedInput-notchedOutline legend": {
    width: "0px",
    height: "0px",
    overflow: "hidden",
    visibility: "hidden",
    padding: 0,
    margin: 0,
  },
};
