import { DeleteOutlined, InfoCircleFilled, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { logout } from "../../../modules/session";
import { Button, Input, Modal, Spin, Alert, Form, Card, Popover } from "antd";
import { isEmpty, isEqual, omitBy } from "lodash";
import React, { useState, Fragment, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import validator from "email-validator";

import { includes } from "lodash";
import { getErrorMessage } from "../../../utils/errors";
import {
  createNewUser,
  removeUser,
  activateUser,
  updateUserInfo,
  findUserWithEmail,
  linkNewUser,
  unLinkUser,
} from "../api";
import { NotificationInput } from "./notificationInput";
import { RolesInput } from "./rolesInput";
import { getActiveUsers } from "src/api/facilityUsers";
import { FACILITY_ROLES } from "src/constants/facility";
import { useFlags } from "launchdarkly-react-client-sdk";
import { VerticalSpacing } from "src/designsystem/VerticalSpacing/VerticalSpacing";
import { useRateNegotiationFlag } from "src/hooks/useRateNegotiationFlag";
import { getSignInLink } from "src/utils/user";
import { routes } from "src/utils/routes";
import { isDefined } from "@clipboard-health/util-ts";
import { showErrorToast } from "@src/appV2/lib/Notifications";

function ModifyUser({ userToEdit, onUpdate, facilityId, facility }) {
  const [loading, setLoading] = useState(false);
  const [userToEditIsLoggedIn, setUserToEditIsLoggedIn] = useState(false);
  const { userId, admin, adminUserId, user, type } = useSelector((state: any) => state.session);
  const [currentEmail, setCurrentEmail] = useState<string>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [foundUser, setFoundUser] = useState<any>();
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [hasFacilityAdmin, setHasFacilityAdmin] = useState(true);

  const { isActive: isRateNegotiationActive } = useRateNegotiationFlag({
    isAdmin: !!admin,
    facilityRateNegotiationFlag: facility?.rateNegotiation,
    facilityId: facility?.userId,
    facilityMsa: facility?.fullAddress?.metropolitanStatisticalArea,
    facilityState: facility?.fullAddress?.state,
  });

  const [form] = Form.useForm();
  const ldFlags = useFlags();

  useEffect(() => {
    if (user._id === userToEdit._id && user.email === userToEdit.email) {
      setUserToEditIsLoggedIn(true);
    }
  }, [user, userToEdit]);

  useEffect(() => {
    const getFacilityUsers = async () => {
      await getActiveUsers(facilityId).then(({ body }) => {
        const hasFacilityAdmin = body.users.some(({ roles }) =>
          roles.includes(FACILITY_ROLES.ADMIN)
        );
        setHasFacilityAdmin(hasFacilityAdmin);
      });
    };
    getFacilityUsers();
  }, [facilityId]);

  const isFacilityAdminUser = useMemo(() => {
    return includes(user.access, FACILITY_ROLES.ADMIN);
  }, [user]);

  const createNotifyRoles = (userInfo) => {
    let alertSettings: any = null;
    const notifySettings = { EMAIL: {}, SMS: {} };

    if (userInfo["granularControl"]) {
      const keys = Object.keys(userInfo["granularControl"]);

      alertSettings = keys.reduce((accOuter, key) => {
        const values = userInfo["granularControl"][key].reduce((acc, item) => {
          if (item.action !== "All" && !notifySettings[key][item.role]) {
            notifySettings[key][item.role] = item.default.enabled;
          }

          return { ...acc, [item.action]: item.default };
        }, {});
        return { ...accOuter, [key]: { ...values } };
      }, {});
    }

    userInfo.alertSettings = alertSettings;
    userInfo.notify = notifySettings;

    return userInfo;
  };

  const shouldLogoutUserAfterSubmit = useMemo(() => {
    if (userToEdit._id === user._id && currentEmail !== userToEdit.email) {
      return true;
    }
    return false;
  }, [userToEdit, user, currentEmail]);

  const isFacilityUser = useMemo(() => {
    return !admin && type === "FACILITY";
  }, [admin, type]);

  const createUser = async (state) => {
    setLoading(true);
    try {
      state = createNotifyRoles(state);

      const userInfo = await createNewUser({
        user: state,
        facilityId: facilityId || userId,
        performedBy: admin ? adminUserId : user,
        signInLink: getSignInLink(routes.root, state.email, true),
      });
      onUpdate(userInfo);
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const linkUser = async () => {
    if (!foundUser) {
      return;
    }
    setLoading(true);
    try {
      const userInfo = await linkNewUser({
        userId: foundUser._id,
        facilityId: facilityId || userId,
        signInLink: currentEmail ? getSignInLink(routes.root, currentEmail, true) : undefined,
      });
      onUpdate(userInfo);
    } catch (error: any) {
      let errMessage = getErrorMessage(error);
      if (error.status === 403 && isFacilityUser) {
        errMessage = "To add an existing user you need to have ADMIN role";
      }
      showErrorToast(errMessage);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (state) => {
    let userInfo = omitBy(state, (value, key) => isEqual(userToEdit[key], value));

    userInfo = createNotifyRoles(userInfo);

    if (isEmpty(userInfo)) {
      onUpdate(state);
      return;
    }

    setLoading(true);
    try {
      const result = await updateUserInfo({
        userId: userToEdit._id,
        firebaseId: userToEdit.firebaseId,
        userInfo,
        facilityId: facilityId || userId,
        performedBy: admin ? adminUserId : user,
      });
      onUpdate(result);
      if (shouldLogoutUserAfterSubmit) {
        logout();
      }
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const confirmRemoveUser = async () => {
    setLoading(true);
    try {
      const result = await removeUser({
        userId: userToEdit._id,
        firebaseId: userToEdit.firebaseId,
        performedBy: admin ? adminUserId : user,
        facilityId: facilityId || userId,
      });
      onUpdate(result);
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    } finally {
      setLoading(false);
      setShowRemoveUserModal(false);
    }
  };

  const confirmUnLinkUser = async () => {
    setLoading(true);
    try {
      const result = await unLinkUser({
        userId: userToEdit._id,
        facilityId: facilityId || userId,
      });
      onUpdate(result);
    } catch (error: any) {
      let errMessage = getErrorMessage(error);
      if (error.status === 403 && isFacilityUser) {
        errMessage = "To remove an existing user you need to have ADMIN role";
      }
      showErrorToast(errMessage);
    } finally {
      setLoading(false);
      setShowRemoveUserModal(false);
    }
  };

  const updateWebPushNotify = async (value) => {
    setLoading(true);
    const userInfo = {
      notify: { ...userToEdit.notify, WEB_PUSH: { All: value } },
    };

    try {
      await updateUserInfo({
        userId: userToEdit._id,
        firebaseId: userToEdit.firebaseId,
        userInfo,
        performedBy: admin ? adminUserId : user,
        facilityId: facilityId || userId,
      });
      if (!userToEdit.notify.WEB_PUSH) {
        userToEdit.notify.WEB_PUSH = {};
      }
      userToEdit.notify = userInfo.notify;
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const closeRemoveUserModal = () => {
    setShowRemoveUserModal(false);
  };

  const onRemoveClick = async () => {
    if (userToEdit.facilities.length < 2) {
      Modal.confirm({
        title: `Do you want to remove ${userToEdit.name}?`,
        onOk: confirmRemoveUser,
      });
    } else {
      // unlink the user directly
      // in case of workplace user with admin role
      if (isFacilityAdminUser) {
        await confirmUnLinkUser();
      } else {
        setShowRemoveUserModal(true);
      }
    }
  };

  const confirmActivateUser = async () => {
    setLoading(true);
    try {
      const result = await activateUser({
        userId: userToEdit._id,
        firebaseId: userToEdit.firebaseId,
        performedBy: admin ? adminUserId : user,
        facilityId: facilityId || userId,
      });
      onUpdate(result);
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const onActivateClick = () => {
    Modal.confirm({
      title: `Do you want to activate ${userToEdit.name}?`,
      onOk: confirmActivateUser,
    });
  };

  const onSave = async (values) => {
    if (foundUser) {
      return linkUser();
    }

    if (isEmpty(userToEdit)) {
      await createUser(values);
    } else {
      await updateUser(values);
    }
    if (userToEditIsLoggedIn) {
      window.location.reload();
    }
  };

  const checkRole = async (rule, value) => {
    if (isEmpty(value)) {
      throw new Error("Please select role");
    }
  };

  useEffect(() => {
    const firstNameValue = userToEdit.firstName || foundUser?.firstName;
    const lastNameValue = userToEdit.lastName || foundUser?.lastName;
    if (userToEdit.email) {
      setCurrentEmail(userToEdit.email);
    }
    if (firstNameValue) {
      setFirstName(firstNameValue);
    }
    if (lastNameValue) {
      setLastName(lastNameValue);
    }
  }, [foundUser]);

  useEffect(() => {
    setFullName(`${firstName} ${lastName}`);
  }, [firstName, lastName]);

  useEffect(() => {
    const getUserDetails = async () => {
      setCheckingEmail(true);
      const user = await findUserWithEmail({
        facilityId: facilityId || userId,
        email: currentEmail,
      });
      setFoundUser(user);
      if (user) {
        form.setFieldsValue({
          name: user.name,
          firstName: user.firstName,
          lastName: user.lastName,
          workplacePhone: user.workplacePhone,
          phone: user.phone,
          designation: user.designation,
          notes: user.notes,
          primary: user.primary,
          roles: user.roles || [],
          notify: user.notify || { SMS: {}, EMAIL: {} },
        });
      }

      setCheckingEmail(false);
    };

    if (isDefined(currentEmail) && validator.validate(currentEmail)) {
      getUserDetails();
    } else {
      setFoundUser(null);
    }
  }, [currentEmail, facilityId, userId]);

  const onEmailChange = (event) => {
    const email = event.target.value;
    setCurrentEmail(email);
  };

  const facilities = (userToEdit.facilities ?? []).filter(Boolean);
  const foundUserDetails = foundUser || {};
  return (
    <Fragment>
      <Form
        form={form}
        labelCol={{ xs: { span: 6 }, sm: { span: 6 } }}
        wrapperCol={{ xs: { span: 18 }, sm: { span: 18 } }}
        onFinish={onSave}
        initialValues={{
          email: userToEdit.email,
          name: fullName,
          firstName: userToEdit.firstName || foundUserDetails.firstName,
          lastName: userToEdit.lastName || foundUserDetails.lastName,
          workplacePhone: userToEdit.workplacePhone || foundUserDetails.workplacePhone,
          phone: userToEdit.phone || foundUserDetails.phone,
          designation: userToEdit.designation || foundUserDetails.designation,
          notes: userToEdit.notes || foundUserDetails.notes,
          primary: userToEdit.primary || foundUserDetails.primary,
          roles: userToEdit.roles || foundUserDetails.roles || [],
          granularControl: { EMAIL: [], SMS: [] },
          legacyControl: userToEdit.notify ||
            foundUserDetails.notify || { EMAIL: {}, SMS: {}, WEB_PUSH: {} },
        }}
      >
        {foundUser && (
          <Alert
            style={{ marginBottom: "10px" }}
            type="warning"
            message="User with this email address already exists. Do you want to link user to this Workplace?"
          />
        )}

        <Form.Item
          label="Name"
          rules={[
            {
              message: "Please input name!",
              whitespace: true,
            },
          ]}
        >
          <Input value={fullName} disabled />
        </Form.Item>

        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please input first name!",
              whitespace: true,
            },
          ]}
        >
          <Input onChange={(value) => setFirstName(value.target.value)} disabled={foundUser} />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please input last name!",
              whitespace: true,
            },
          ]}
        >
          <Input onChange={(value) => setLastName(value.target.value)} disabled={foundUser} />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          validateTrigger="onBlur"
          /* @ts-expect-error FIXME this wrong type */
          onBlur={onEmailChange}
          rules={[
            {
              type: "email",
              message: "Invalid email!",
            },
            {
              required: true,
              message: "Please input email!",
            },
          ]}
        >
          <Input
            onChange={onEmailChange}
            type="email"
            addonBefore={checkingEmail && <Spin size="small" />}
          />
        </Form.Item>

        <Form.Item
          label={
            <>
              <Popover
                placement="bottom"
                overlayStyle={{ maxWidth: "50%", overflowWrap: "break-word" }}
                content="Enter a valid mobile number to receive SMS alerts"
              >
                <InfoCircleFilled style={{ fontSize: 16, color: "#2B96FC", marginRight: "5px" }} />
              </Popover>{" "}
              Mobile Phone
            </>
          }
          style={{ marginBottom: 0 }}
        >
          <Form.Item
            name="phone"
            validateTrigger="onBlur"
            rules={[{ len: 10, message: "Invalid phone!" }]}
            style={{ display: "inline-block", width: "30%" }}
          >
            <Input
              type="tel"
              maxLength={10}
              disabled={foundUser}
              className="transparent-input-popover"
            />
          </Form.Item>

          <span
            style={{
              display: "inline-block",
              width: "15%",
              lineHeight: "32px",
              textAlign: "right",
              textOverflow: "clip",
              marginRight: "5px",
            }}
          >
            Landline :
          </span>
          <Form.Item
            name="workplacePhone"
            validateTrigger="onBlur"
            rules={[{ len: 10, message: "Invalid landline!" }]}
            style={{ display: "inline-block", width: "30%" }}
          >
            <Input type="tel" maxLength={10} disabled={foundUser} />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Job Title" name="designation">
          <Input disabled={foundUser} />
        </Form.Item>
        {!hasFacilityAdmin && (
          <Card
            style={{
              borderColor: "#ff4d4f",
              marginBottom: "20px",
              backgroundColor: "#ff4d4f1a",
            }}
          >
            This account has no user with ADMIN permission and may create limitations to the user
            experience. Please make sure to add admin permission to a user or escalate this issue to
            the appropriate team.
          </Card>
        )}
        <Form.Item label="Roles" name="roles" rules={[{ required: true, validator: checkRole }]}>
          {/* @ts-expect-error FIXME this wrong type */}
          <RolesInput disabled={foundUser} userToEditIsLoggedIn={userToEditIsLoggedIn} />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.roles !== currentValues.roles}
        >
          {({ getFieldValue }) => (
            <Form.Item label="Notifications" name="granularControl">
              <NotificationInput
                userGranularSettings={
                  userToEdit.alertSettings ||
                  foundUserDetails.alertSettings || { EMAIL: {}, SMS: {} }
                }
                userLegacySettings={getFieldValue("legacyControl")}
                roles={getFieldValue("roles")}
                disabled={foundUser}
                userToEdit={userToEdit}
                userToEditIsLoggedIn={userToEditIsLoggedIn}
                updateWebPushNotify={updateWebPushNotify}
                facilityFlags={ldFlags}
                isRateNegotiationActive={isRateNegotiationActive}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }} className="align-center">
          {userToEdit._id && !userToEdit.archived && (
            <Button
              /* @ts-expect-error FIXME this wrong type */
              type="danger"
              ghost={true}
              size="large"
              icon={<DeleteOutlined />}
              loading={loading}
              onClick={onRemoveClick}
              data-testid="remove-user-button"
              style={{ marginRight: "10px" }}
            >
              Remove User
            </Button>
          )}
          {userToEdit.archived && (
            <Button
              type="primary"
              ghost={true}
              size="large"
              icon={<UndoOutlined />}
              loading={loading}
              onClick={onActivateClick}
              style={{ marginRight: "10px" }}
            >
              Activate User
            </Button>
          )}
          {foundUser ? (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<SaveOutlined />}
              loading={loading}
            >
              Link User
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<SaveOutlined />}
              loading={loading}
            >
              Save {shouldLogoutUserAfterSubmit && " & log out"}
            </Button>
          )}
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: -24 }} className="align-center">
          {shouldLogoutUserAfterSubmit && (
            <span style={{ color: "#ff4d4f" }}>
              After editing your own email you must log out and log back in with the new email
              address
            </span>
          )}
        </Form.Item>
      </Form>
      {/* This modal is only show if number of facilities is larger than 2 */}
      <Modal
        title="Unlink or Suspend User"
        onCancel={closeRemoveUserModal}
        visible={showRemoveUserModal}
        destroyOnClose={true}
        width="60%"
        footer={[
          <Button key="cancel" size="large" loading={loading} onClick={closeRemoveUserModal}>
            Cancel
          </Button>,
          <Button
            key="unlink"
            type="primary"
            ghost={true}
            size="large"
            icon={<DeleteOutlined />}
            loading={loading}
            onClick={confirmUnLinkUser}
          >
            Unlink User
          </Button>,
          <Button
            key="suspend"
            /* @ts-expect-error FIXME this wrong type */
            type="danger"
            ghost={true}
            size="large"
            icon={<DeleteOutlined />}
            loading={loading}
            onClick={confirmRemoveUser}
          >
            Suspend User
          </Button>,
        ]}
      >
        <div>
          <p>This user is linked to multiple workplaces:</p>
          <ul>
            {facilities.map((facility) => (
              <li key={facility.userId}>{facility.name}</li>
            ))}
          </ul>
          <VerticalSpacing size="md" />
          <p>
            Do you want to suspend this user from all of the workplaces or unlink from this
            workplace only?
          </p>
        </div>
      </Modal>
    </Fragment>
  );
}

export { ModifyUser };
