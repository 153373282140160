import { UseInfiniteScrollHookRefCallback } from "react-infinite-scroll-hook";
import { Agent, Shift, WorkerReview as Review } from "src/interface";
import { Dispatch, MouseEvent } from "react";
import { ResultStatusType } from "antd/lib/result";
import { WorkerReviewDetails } from "src/api/workerReview";

export type WorkerDetails = Pick<
  Agent,
  "name" | "qualification" | "profileImageUrl" | "userId" | "phone" | "email"
> & { matchingFacilityQualifications: string[] };

export type ShiftDetails = Pick<
  Shift,
  "agentId" | "_id" | "start" | "end" | "name" | "agentReq" | "instantPayDetails" | "facility"
> & {
  _id: string;
  agentId: string;
};

export type WorkerReview = Pick<Review, "workerId" | "workplaceId" | "id" | "shiftId" | "rating">;

export type ExclusionDetails = {
  _id: string;
  agent?: string;
  facility?: string;
};

export interface WorkplaceWorkerDnrModalProps
  extends Pick<
      UseWorkplaceWorkerReturnType,
      | "shiftDetails"
      | "exclusion"
      | "dispatch"
      | "workerDetails"
      | "exclusionMode"
      | "showExclusionModal"
      | "lastWorkedShift"
    >,
    UseWorkplaceWorkerProps {
  userType: string;
  activeTab: ShiftDetailsType;
}

export type AssignedShiftsCount = {
  inprogress: number;
  past: number;
  total: number;
  upcoming: number;
  lastWorkedShift?: ShiftDetails;
};

export type AssignedShiftsResponse = {
  shifts: ShiftDetails[];
  pageToken?: string | null;
};

export type ExclusionMode = "block" | "unblock";

export interface WorkplaceWorkerState {
  /**
   * This field is used to determine if at least a single shift is worked by a worker for a workplace.
   */
  lastWorkedShift?: ShiftDetails;
  /**
   * This is the URL generated using workedId_workplaceUserId to open a chat thread.
   */
  chatChannelUrl?: string;
  workerDetails?: WorkerDetails;
  selectedCancellingShift?: ShiftDetails;
  showCancelShiftModal: boolean;
  showExclusionModal: boolean;
  exclusion?: ExclusionDetails;
  exclusionMode: ExclusionMode;
  shiftDetails: {
    inprogress: AssignedShiftsResponse;
    past: AssignedShiftsResponse;
    upcoming: AssignedShiftsResponse;
  };
  shiftsCount?: AssignedShiftsCount;
  workerReviews?: WorkerReview[];
  workerRating: number;
  activeTab: ShiftDetailsType;
  isError: {
    shiftDetails: { inprogress: boolean; past: boolean; upcoming: boolean };
    shiftsCount: boolean;
    exclusion: boolean;
    workerDetails: boolean | ResultStatusType;
    workerReviews: boolean;
  };
  isLoading: {
    shiftDetails: { inprogress: boolean; past: boolean; upcoming: boolean };
    shiftsCount: boolean;
    exclusion: boolean;
    workerDetails: boolean;
    workerReviews: boolean;
  };
}

export enum ReducerAction {
  /**
   * Handles the aggregated grouped values of shifts based on `start` date in the backed.
   * Data structure of the API response is {@link AssignedShiftsCount}.
   */
  SET_ASSIGNED_SHIFTS_COUNT = "SET_ASSIGNED_SHIFTS_COUNT",
  /**
   * Handles the shifts list based on the user selected tab {@link ShiftDetailsType}
   */
  SET_ASSIGNED_SHIFTS = "SET_ASSIGNED_SHIFTS",
  /**
   * Handles **only** shifts list pagination loading state, which is listened by a `useEffect` to make API calls.
   */
  LOAD_MORE_ASSIGNED_SHIFTS = "LOAD_MORE_ASSIGNED_SHIFTS",
  /**
   * Sets details related to worker who is currently workplace user/cbh employee is viewing.
   */
  SET_WORKER_DETAILS = "SET_WORKER_DETAILS",
  /**
   * Set details of exclusion or DNRs between a `worker` and `workplace`.
   */
  SET_EXCLUSION_DETAILS = "SET_EXCLUSION_DETAILS",
  /**
   * Set details of worker review data between a `worker` and `workplace`, this will be later changed to worker reviews.
   */
  SET_WORKER_REVIEWS = "SET_WORKER_REVIEWS",
  SET_ERROR_ON_ASSIGNED_SHIFTS = "SET_ERROR_ON_ASSIGNED_SHIFTS",
  SET_ERROR_ON_EXCLUSION = "SET_ERROR_ON_EXCLUSION",
  SET_ERROR_ON_WORKER_REVIEWS = "SET_ERROR_ON_WORKER_REVIEWS",
  SET_ERROR_ON_WORKER_DETAILS = "SET_ERROR_ON_WORKER_DETAILS",
  SHOW_BLOCK_OR_UNBLOCK_MODAL = "SHOW_BLOCK_OR_UNBLOCK_MODAL",
  HIDE_BLOCK_OR_UNBLOCK_MODAL = "HIDE_BLOCK_OR_UNBLOCK_MODAL",
  SHOW_CANCEL_SHIFT_MODAL = "SHOW_CANCEL_SHIFT_MODAL",
  HIDE_CANCEL_SHIFT_MODAL = "HIDE_CANCEL_SHIFT_MODAL",
  /**
   * THIS ACTION IS USED, WHEN A SHIFT IS DELETED BECAUSE OF FACILITY CANCELLATION.
   */
  REMOVE_SHIFT_FROM_VIEW = "REMOVE_SHIFT_FROM_VIEW",
  SHOW_CHAT_MODAL = "SHOW_CHAT_MODAL",
  HIDE_CHAT_MODAL = "HIDE_CHAT_MODAL",
  UPDATE_TAB_PANE = "UPDATE_TAB_PANE",
}

/**
 * These values match the properties received from the backend for shifts-count.
 */
export enum ShiftDetailsType {
  INPROGRESS = "inprogress",
  PAST = "past",
  UPCOMING = "upcoming",
}

export type ReducerActions =
  | {
      type: ReducerAction.SET_WORKER_DETAILS;
      payload: WorkerDetails;
    }
  | {
      type: ReducerAction.SET_ASSIGNED_SHIFTS_COUNT;
      payload: AssignedShiftsCount;
    }
  | {
      type: ReducerAction.SET_ASSIGNED_SHIFTS;
      payload: {
        pageToken?: string | null;
        shifts: ShiftDetails[];
        type: ShiftDetailsType;
      };
    }
  | { type: ReducerAction.SET_EXCLUSION_DETAILS; payload?: ExclusionDetails }
  | { type: ReducerAction.UPDATE_TAB_PANE; payload: ShiftDetailsType }
  | { type: ReducerAction.SET_WORKER_REVIEWS; payload: WorkerReviewDetails[] }
  | { type: ReducerAction.LOAD_MORE_ASSIGNED_SHIFTS }
  | { type: ReducerAction.HIDE_BLOCK_OR_UNBLOCK_MODAL }
  | { type: ReducerAction.SHOW_BLOCK_OR_UNBLOCK_MODAL }
  | {
      type: ReducerAction.SHOW_CANCEL_SHIFT_MODAL;
      payload: { shift: ShiftDetails };
    }
  | { type: ReducerAction.HIDE_CANCEL_SHIFT_MODAL }
  | { type: ReducerAction.REMOVE_SHIFT_FROM_VIEW; payload: { shiftId } }
  | { type: ReducerAction.SHOW_CHAT_MODAL; payload: string }
  | { type: ReducerAction.HIDE_CHAT_MODAL }
  | { type: ReducerAction.SET_ERROR_ON_ASSIGNED_SHIFTS }
  | { type: ReducerAction.SET_ERROR_ON_EXCLUSION }
  | { type: ReducerAction.SET_ERROR_ON_WORKER_REVIEWS }
  | {
      type: ReducerAction.SET_ERROR_ON_WORKER_DETAILS;
      payload: WorkplaceWorkerState["isError"]["workerDetails"];
    };

/**
 * This parameters are required in all the components. so they are added here so this can be extendable everywhere.
 */
export interface WorkplaceWorkerGlobalQueryParameters {
  workplaceId: string;
  workerId: string;
}

export interface HTTPError extends Error {
  status?: ResultStatusType;
}

/**
 * These are the props for custom-hook with name: `useWorkplaceWorker`
 */
export interface UseWorkplaceWorkerProps extends WorkplaceWorkerGlobalQueryParameters {
  admin?: boolean | string;
  adminUserId?: string;
  workplaceUserId?: string;
}

export interface UseWorkplaceWorkerReturnType extends WorkplaceWorkerState {
  /**
   * Intersection observer ref, that adds pagination to the list view and helps make api call on-demand.
   */
  infiniteScrollRef: UseInfiniteScrollHookRefCallback;
  dispatch: Dispatch<ReducerActions>;
  /**
   * Callback function to open review modal to add/update a shift for a worker.
   */
  openWorkerReviewModal: () => void;
  /**
   * Callback function to open dnr modal to add/update exclusions.
   */
  openDnrModal: () => void;
  /**
   * Callback function to open chat modal to chat with an agent.
   */
  openChatModal: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Callback function to view document.
   */
  onViewDocument: () => void;
  /**
   * Callback that triggered from cancellation modal to make api to cancel a shift.
   */
  onConfirmCancelShift: (reason: string, reasonDescription: string) => Promise<void>;
}

export type FetchAssignedShiftsCount = WorkplaceWorkerGlobalQueryParameters;

export interface FetchAssignedShifts extends WorkplaceWorkerGlobalQueryParameters {
  category: ShiftDetailsType;
  pageToken?: string | null;
}

export interface FetchAssignedShiftsReturnType {
  shifts: ShiftDetails[];
  pageToken?: string | null;
}

export type FetchAgentDetails = Pick<
  WorkplaceWorkerGlobalQueryParameters,
  "workerId" | "workplaceId"
>;

export interface RootStoreState {
  session: {
    userId: string;
    admin: boolean;
    profile?: {
      _id: string;
      userId: string;
      name: string;
      fullAddress: {
        metropolitanStatisticalArea: string;
        city: string;
        state: string;
      };
    };
    type: string;
    user: { access: string[]; _id: string };
    flags?: { canCancelFilledShifts: boolean };
  };
}

export interface ShiftDetailsTabProps
  extends Pick<WorkplaceWorkerState, "shiftDetails" | "shiftsCount" | "activeTab" | "isLoading"> {
  dispatch: Dispatch<ReducerActions>;
  canCancelShift: boolean;
}

export interface ShiftDetailsProps
  extends Pick<ShiftDetailsTabProps, "dispatch" | "canCancelShift"> {
  shift: ShiftDetails;
}

export interface StyledComponentTextProps {
  fontSize?: string;
  lineHeight?: string;
  color?: string;
}

export interface ShiftDetailsListProps
  extends Pick<ShiftDetailsTabProps, "dispatch" | "canCancelShift"> {
  shifts: ShiftDetails[];
  emptyMessage: string;
  isLoading: boolean;
}
