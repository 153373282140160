// Get Pacific Time Sunday to Sunday bounded week from moment date
import moment from "moment-timezone";

/**
 * @deprecated
 * Use date-fns instead. See ./dateTimeFns.ts
 */
function weekBoundsFromDateSunPacific(momentDate) {
  const pacificDateStart = momentDate.clone().tz("America/Los_Angeles").startOf("week");
  const pacificDateEnd = momentDate.clone().tz("America/Los_Angeles").endOf("week");

  return {
    start: pacificDateStart,
    end: pacificDateEnd,
  };
}

/**
 * @deprecated
 * Use date-fns instead. See ./dateTimeFns.ts
 */
export function weekBoundsFromDateSunPacificArray(momentDate) {
  const { start, end } = weekBoundsFromDateSunPacific(momentDate);
  return [start, end];
}

/**
 * @deprecated
 * Use date-fns instead. See ./dateTimeFns.ts
 */
export function getOffsetFormattedTextBetweenTimezones(date, baseTimezone, timezone) {
  const baseTimezoneOffset = moment(date).tz(baseTimezone).utcOffset();
  const timezoneOffset = moment(date).tz(timezone).utcOffset();
  const diffInMinutes = Math.abs(timezoneOffset - baseTimezoneOffset);
  if (diffInMinutes === 0) {
    return "";
  }
  const diffInHrs = Math.floor(diffInMinutes / 60);
  const remainingMins = diffInMinutes % 60;
  let offsetText = "";
  if (diffInHrs > 0) {
    offsetText += `${diffInHrs} ${diffInHrs === 1 ? "hr " : "hrs "}`;
  }
  if (remainingMins > 0) {
    offsetText += `${remainingMins} mins `;
  }
  return `${offsetText}${timezoneOffset < baseTimezoneOffset ? "earlier" : "later"}`;
}
