import {
  ReducerAction,
  ReducerActions,
  ShiftDetailsType,
  WorkplaceWorkerState,
} from "./workplaceWorker.types";

export const initialState: WorkplaceWorkerState = {
  shiftDetails: {
    inprogress: { shifts: [] },
    upcoming: { shifts: [] },
    past: { shifts: [] },
  },
  activeTab: ShiftDetailsType.UPCOMING,
  workerRating: 0,
  showCancelShiftModal: false,
  showExclusionModal: false,
  exclusionMode: "block",
  isError: {
    workerDetails: false,
    shiftDetails: { inprogress: false, past: false, upcoming: false },
    exclusion: false,
    shiftsCount: false,
    workerReviews: false,
  },
  isLoading: {
    workerDetails: true,
    shiftDetails: { inprogress: false, past: false, upcoming: true },
    exclusion: true,
    shiftsCount: true,
    // Get this data after shiftsCount because shift-review uses `lastWorkedShift` data.
    workerReviews: false,
  },
};

export const reducer = (
  state: WorkplaceWorkerState,
  action: ReducerActions
): WorkplaceWorkerState => {
  switch (action.type) {
    case ReducerAction.SET_WORKER_DETAILS: {
      return {
        ...state,
        workerDetails: action.payload,
        isLoading: {
          ...state.isLoading,
          workerDetails: false,
        },
        isError: {
          ...state.isError,
          workerDetails: false,
        },
      };
    }
    case ReducerAction.SET_ASSIGNED_SHIFTS_COUNT: {
      const { lastWorkedShift, ...shiftsCount } = action.payload;
      return {
        ...state,
        lastWorkedShift,
        shiftsCount,
        isLoading: {
          ...state.isLoading,
          shiftsCount: false,
          workerReviews: true,
        },
        isError: {
          ...state.isError,
          shiftsCount: false,
        },
      };
    }
    case ReducerAction.SET_ASSIGNED_SHIFTS: {
      return {
        ...state,
        shiftDetails: {
          ...state.shiftDetails,
          [action.payload.type]: {
            shifts: [...state.shiftDetails[action.payload.type].shifts, ...action.payload.shifts],
            pageToken: action.payload.pageToken,
          },
        },
        isLoading: {
          ...state.isLoading,
          shiftDetails: {
            ...state.isLoading.shiftDetails,
            [action.payload.type]: false,
          },
        },
        isError: {
          ...state.isError,
          shiftDetails: {
            ...state.isError.shiftDetails,
            [action.payload.type]: false,
          },
        },
      };
    }
    case ReducerAction.SET_ERROR_ON_WORKER_DETAILS: {
      return {
        ...state,
        isError: {
          ...state.isError,
          workerDetails: action.payload ?? true,
        },
        isLoading: {
          ...state.isLoading,
          workerDetails: false,
        },
      };
    }
    case ReducerAction.SET_ERROR_ON_ASSIGNED_SHIFTS: {
      return {
        ...state,
        isError: {
          ...state.isError,
          shiftDetails: {
            ...state.isError.shiftDetails,
            [state.activeTab]: true,
          },
        },
        isLoading: {
          ...state.isLoading,
          shiftDetails: {
            ...state.isLoading.shiftDetails,
            [state.activeTab]: false,
          },
        },
      };
    }
    case ReducerAction.SET_ERROR_ON_EXCLUSION: {
      return {
        ...state,
        isError: {
          ...state.isError,
          exclusion: true,
        },
        isLoading: {
          ...state.isLoading,
          exclusion: false,
        },
      };
    }
    case ReducerAction.SET_ERROR_ON_WORKER_REVIEWS: {
      return {
        ...state,
        isError: {
          ...state.isError,
          workerReviews: true,
        },
        isLoading: {
          ...state.isLoading,
          workerReviews: false,
        },
      };
    }
    case ReducerAction.LOAD_MORE_ASSIGNED_SHIFTS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          shiftDetails: {
            ...state.isLoading.shiftDetails,
            [state.activeTab]: !!state.shiftDetails[state.activeTab].pageToken,
          },
        },
      };
    }
    case ReducerAction.SET_EXCLUSION_DETAILS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          exclusion: false,
        },
        showExclusionModal: false,
        exclusionMode: action.payload ? "unblock" : "block",
        exclusion: action.payload,
      };
    }
    case ReducerAction.SHOW_BLOCK_OR_UNBLOCK_MODAL: {
      return {
        ...state,
        showExclusionModal: true,
      };
    }
    case ReducerAction.HIDE_BLOCK_OR_UNBLOCK_MODAL: {
      return {
        ...state,
        showExclusionModal: false,
      };
    }
    case ReducerAction.SHOW_CHAT_MODAL: {
      return {
        ...state,
        chatChannelUrl: action.payload,
      };
    }
    case ReducerAction.HIDE_CHAT_MODAL: {
      return {
        ...state,
        chatChannelUrl: undefined,
      };
    }
    case ReducerAction.SHOW_CANCEL_SHIFT_MODAL: {
      return {
        ...state,
        showCancelShiftModal: true,
        selectedCancellingShift: action.payload.shift,
      };
    }
    case ReducerAction.REMOVE_SHIFT_FROM_VIEW: {
      const activeTabCount = state.shiftsCount?.[state.activeTab] ?? 0;
      const totalCount = state.shiftsCount?.total ?? 0;

      return {
        ...state,
        ...(state.shiftsCount
          ? {
              shiftsCount: {
                ...state.shiftsCount,
                // We are removing a shift from the user view on shift cancellation. So decreasing count of shifts present in the UI for real-time updates.
                [state.activeTab]: activeTabCount >= 1 ? activeTabCount - 1 : activeTabCount,
                total: totalCount >= 1 ? totalCount - 1 : totalCount,
              },
            }
          : {}),
        shiftDetails: {
          ...state.shiftDetails,
          [state.activeTab]: {
            ...state.shiftDetails[state.activeTab],
            shifts: state.shiftDetails[state.activeTab].shifts.filter(
              ({ _id }) => _id !== action.payload.shiftId
            ),
          },
        },
      };
    }
    case ReducerAction.HIDE_CANCEL_SHIFT_MODAL: {
      return {
        ...state,
        selectedCancellingShift: undefined,
        showCancelShiftModal: false,
      };
    }
    case ReducerAction.UPDATE_TAB_PANE: {
      return {
        ...state,
        activeTab: action.payload,
        isLoading: {
          ...state.isLoading,
          shiftDetails: {
            ...state.isLoading.shiftDetails,
            // Load data only in-case, when there is no data in the changing tab.
            [action.payload]: !state.shiftDetails[action.payload].shifts.length,
          },
        },
      };
    }
    case ReducerAction.SET_WORKER_REVIEWS: {
      const latestWorkerReview =
        action.payload.length > 0
          ? action.payload.reduce((prevReview, currentReview) =>
              new Date(prevReview.createdAt).getTime() > new Date(currentReview.createdAt).getTime()
                ? prevReview
                : currentReview
            )
          : undefined;
      return {
        ...state,
        workerRating: latestWorkerReview?.rating ?? 0,
        isLoading: {
          ...state.isLoading,
          workerReviews: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
