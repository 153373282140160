export enum SHIFT_CANCELLATION_REASON_TYPES {
  LOW_CENSUS = "LOW_CENSUS",
  STAFFED_IN_HOUSE = "STAFFED_IN_HOUSE",
  STAFFED_OTHER_REGISTRY = "STAFFED_OTHER_REGISTRY",
  NO_CALL_NO_SHOW = "NO_CALL_NO_SHOW",
  FACILITY_USER_SUBMIT_SENT_HOME = "FACILITY_USER_SUBMIT_SENT_HOME",
  WORKER_IS_LATE = "WORKER_IS_LATE",
  OTHER = "OTHER",
}

export const WORKER_LEFT_EARLY_REASON_TYPE = "WORKER_LEFT_EARLY";

export const ADDITIONAL_SHIFT_CANCELLATION_REASON_TYPES = {
  WORKER_SHOULD_BE_BLOCKED: "WORKER_SHOULD_BE_BLOCKED",
  WRONG_SHIFT: "WRONG_SHIFT",
  WORKER_CANT_WORK: "WORKER_CANT_WORK",
};

export const WORKER_CANCELLATION_REASON_TYPES = {
  SICK: "SICK",
  PAYMENT_ISSUE: "PAYMENT_ISSUE",
  FACILITY_ISSUE: "FACILITY_ISSUE",
  SWITCHING_SHIFTS: "SWITCHING_SHIFTS",
  OTHER: "OTHER",
};

export const NCNS_REASON_TYPES = {
  BEFORE_SHIFT_START_ISSUE: "BEFORE_SHIFT_START_ISSUE",
  AFTER_SHIFT_START_ISSUE: "AFTER_SHIFT_START_ISSUE",
};

export const INVOICE_STATUS = {
  NOT_PAID: "NOT_PAID",
  PAID: "PAID",
  PROCESSING: "PROCESSING",
  PARTIALLY_PAID: "PARTIALLY_PAID",
  PARTIALLY_PROCESSING: "PARTIALLY_PROCESSING",
  PARTIALLY_PAID_AND_PROCESSING: "PARTIALLY_PAID_AND_PROCESSING",
  OVERPAID: "OVERPAID",
  PENDING: "pending",
  BAD_DEBT: "bad_debt",
};

export const MAX_SHIFT_RATE = 1000000;
