export enum DnrAction {
  CREATE = "CREATE",
  DELETE = "DELETE",
}

/**
 * These values are used for analytics purpose to determine, where the component is rendering.
 */
export enum WhoCalledRatingPerformanceModal {
  /**
   * If the component is rendered, when we are calling it from shift-details screen, select this value.
   */
  SHIFT_DETAILS = "shiftDetails",
  /**
   * If the component is rendered, when we are calling it from chat channel screen, set this value.
   */
  CHAT_CHANNEL = "chatChannel",
  /**
   * This property is used, in case when the rating performance modal is opened from worker details page.
   */
  WORKER_DETAILS = "workerDetails",
  /**
   * If the component is rendered, when we are calling it from workers screen, set this value.
   */
  WORKERS_PAGE = "workersPage",
  /**
   * If the component is rendered, when we are calling it from workers carousel, set this value.
   */
  WORKER_RATINGS_CAROUSEL = "workerRatingsCarousel",
  /**
   * If the component is rendered, when we are calling it from high rating worker details page, set this value.
   */
  HIGH_RATING_WORKER_PAGE = "highRatingWorkerPage",
}

export enum WorkerReviewMode {
  /**
   * Only allowed to view the rating performance, but not make any update/create actions.
   */
  VIEW = "VIEW",
  /**
   * user can create/update rating performance using this action.
   */
  EDIT = "EDIT",
  /**
   * user only submit a rating performance without knowing last review.
   */
  SUBMIT = "SUBMIT",
}

export interface RatingPerformanceState {
  rating?: number;
  additionalFeedback?: string;
  dnrWorker?: boolean;
  dnrReason?: string;
  qualities: string[];
  showDnr?: boolean;
  /**
   * This is updated, whenever user manually touched the dnr checkbox.
   */
  dnrFieldTouched?: boolean;
  mode: WorkerReviewMode;
  // If this exists, that mean this professional is already DNRed.
  exclusionId?: string;
  // If we are editing an already existing rating performance, this value will be defined.
  workerReviewId?: string;
  shiftId?: string;
  reviewerName?: string;
  isLoading?: boolean;
  updatedAt?: string;
}
