import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { get, type ApiResponse, type CommonApiParams } from "./api";
import { type ReactQueryMeta } from "./types";

type UseQueryResponseType<ResponseSchema> = ApiResponse<ResponseSchema>["data"];

type LocallyDefinedUseQueryOptions = "meta" | "queryKey" | "queryFn";
type DisallowedUseQueryOptions = "onSuccess" | "onError" | "onSettled";

export type UseGetQueryOptions<
  ResponseSchema,
  SelectData = UseQueryResponseType<ResponseSchema>
> = Omit<
  UseQueryOptions<
    UseQueryResponseType<ResponseSchema>,
    AxiosError,
    SelectData,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >,
  LocallyDefinedUseQueryOptions | DisallowedUseQueryOptions
>;

export type UseGetQueryProps<
  RequestSchema,
  ResponseSchema,
  SelectSchema = ResponseSchema
> = CommonApiParams<RequestSchema, ResponseSchema> &
  UseGetQueryOptions<ResponseSchema, SelectSchema> & {
    // We require `meta`
    meta: ReactQueryMeta;
  };

export function useGetQuery<RequestSchema, ResponseSchema, SelectSchema = ResponseSchema>(
  props: UseGetQueryProps<RequestSchema, ResponseSchema, SelectSchema>
): UseQueryResult<SelectSchema> {
  const {
    url,
    queryParams,
    requestSchema,
    responseSchema,
    customHeaders,
    paramsSerializer,
    ...options
  } = props;

  return useQuery({
    ...options,
    queryKey: [url, queryParams],
    queryFn: async () => {
      const response = await get({
        url,
        queryParams,
        requestSchema,
        responseSchema,
        customHeaders,
        paramsSerializer,
      });
      return response.data;
    },
  });
}
