import { z } from "zod";
import { AxiosError } from "axios";
import {
  InfiniteData,
  QueryClient,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { API_TYPES, get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import {
  GetPlacementsForWorkplaceQuerySchema,
  GetPlacementsResponse,
  paginatedGetPlacementsResponseSchema,
  PlacementDataSchema,
  PlacementIncludedApplication,
  PlacementIncludedWorkplace,
  PlacementResponse,
} from "../types/fetchPlacements.schema";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { getUserType } from "@src/appV2/PlacementCandidates/utils/helper";
import { useSession } from "@src/appV2/Auth/api/useSession";

export function fetchPlacementsApiUrl(workplaceId: string) {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/workplaces/${workplaceId}/placements`;
}

export const getPlacementsForWorkplaceQuerySchema = z.object({
  sort: z.string().optional(),
  filter: z
    .object({
      workplaceId: z.string().optional(),
      status: z.string().optional(),
      workerTypes: z.string().optional(),
      jobTypes: z.string().optional(),
      shiftTypes: z.string().optional(),
      minPay: z
        .object({
          amountInMinorUnits: z.string().optional(),
          currencyCode: z.literal("USD"),
        })
        .optional(),
      maxPay: z
        .object({
          amountInMinorUnits: z.string().optional(),
          currencyCode: z.literal("USD"),
        })
        .optional(),
    })
    .optional(),
});
export type GetPlacementsForWorkplaceQuery = z.infer<typeof getPlacementsForWorkplaceQuerySchema>;

type FetchPlacementsParams = {
  workplaceId: string;
  queryParams: GetPlacementsForWorkplaceQuery;
};

export function getFetchPaginatedPlacementsQueryKey({
  workplaceId,
  queryParams,
}: FetchPlacementsParams) {
  return [fetchPlacementsApiUrl(workplaceId), queryParams];
}

export function useFetchPaginatedPlacements(
  params: FetchPlacementsParams,
  options: UseInfiniteQueryOptions<GetPlacementsResponse, AxiosError> = {}
) {
  const { workplaceId, queryParams } = params;
  const { profile, admin, user } = useSession();
  return useInfiniteQuery({
    queryKey: getFetchPaginatedPlacementsQueryKey({ workplaceId, queryParams }),
    queryFn: async ({
      pageParam: pageParameter = undefined,
    }: QueryFunctionContext<QueryKey, string | undefined>) => {
      const response = await get({
        url: fetchPlacementsApiUrl(workplaceId),
        queryParams: {
          ...queryParams,
          page: {
            size: 20,
            cursor: pageParameter,
          },
        },
        responseSchema: paginatedGetPlacementsResponseSchema,
        requestSchema: GetPlacementsForWorkplaceQuerySchema,
      });
      logEvent(APP_V2_USER_EVENTS.PLACEMENT_PAGE_VIEWED, {
        workplaceId,
        userType: getUserType(profile, admin),
        userId: user?._id,
        email: user?.email,
        queryParams,
      });
      return response.data;
    },
    getNextPageParam: (lastPage) => lastPage.links.nextCursor ?? undefined,
    ...options,
  });
}

export function updatePaginatedPlacements(
  queryClient: QueryClient,
  queryKey: QueryKey,
  updatedPlacement: PlacementResponse
) {
  // update the selected placement using setQueryData
  queryClient.setQueryData(queryKey, (oldData: InfiniteData<GetPlacementsResponse> | undefined) => {
    if (!oldData) {
      return undefined;
    }

    // it is possible that the included workspace has changed
    const newWorkplaceIncluded = updatedPlacement.included.find(
      (included) => included.type === API_TYPES.workplace
    );

    // get the query params
    const queryParams = queryKey[1] as GetPlacementsForWorkplaceQuery;

    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        data: page.data
          .map((placement) =>
            placement.id === updatedPlacement.data.id ? updatedPlacement.data : placement
          )
          // handle the case where the workplace id is changed on the placement
          .filter(
            (placement) =>
              !queryParams.filter?.workplaceId ||
              queryParams.filter?.workplaceId.length === 0 ||
              queryParams.filter?.workplaceId.includes(placement.relationships.workplace.data.id)
          ),
        // update the workplace included if it has changed
        included: page.included.map((included) =>
          included.type === API_TYPES.workplace && included.id === newWorkplaceIncluded?.id
            ? newWorkplaceIncluded
            : included
        ),
      })),
    };
  });

  // mark placement queries with a different filter as stale
  queryClient.invalidateQueries({
    predicate: (query) => /\/workplaces\/\w+\/placements/.test((query.queryKey[0] ?? "") as string),
  });
}

export function transformResponseIntoPlacement(
  placement: z.infer<typeof PlacementDataSchema>,
  placementIncludedMetaData: GetPlacementsResponse["included"]
) {
  const workplace = placementIncludedMetaData.find(
    (workplace) =>
      workplace.type === API_TYPES.workplace &&
      workplace.id === placement.relationships.workplace.data.id
  ) as PlacementIncludedWorkplace | undefined;

  if (!workplace) {
    throw new Error("Workplace details not found");
  }

  const applications = placement.relationships.applications?.data.map((application) => {
    const applicationData = placementIncludedMetaData.find(
      (included) =>
        included.type === API_TYPES.placementApplication && included.id === application.id
    ) as PlacementIncludedApplication | undefined;
    if (!applicationData) {
      throw new Error("Application details not found");
    }

    return applicationData;
  });

  return {
    ...placement.attributes,
    id: placement.id,
    workplace: {
      id: workplace.id,
      name: (workplace.attributes as { name: string }).name,
    },
    applications,
  };
}

export type PlacementWithDetails = ReturnType<typeof transformResponseIntoPlacement>;
