import React from "react";
import { Select, MenuItem, FormControl, SelectChangeEvent, FormHelperText } from "@mui/material";
import { selectStyles, menuItemStyles } from "./dnrFormStyles";

interface DnrReasonSelectProps {
  value?: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  placeholder: string;
  fullWidth: boolean;
  reasonError: boolean;
  helperText: string;
  reasons: Array<{ value: string; label?: string; dnrCharacterFloor: number }>;
}

/**
 * Component for selecting DNR reasons
 */
export const DnrReasonSelect: React.FC<DnrReasonSelectProps> = ({
  value,
  onChange,
  placeholder,
  fullWidth,
  reasonError,
  helperText,
  reasons,
}) => {
  return (
    <FormControl fullWidth={fullWidth} variant="outlined" error={reasonError}>
      <Select
        value={value || ""}
        onChange={onChange}
        displayEmpty
        error={reasonError}
        sx={selectStyles}
      >
        <MenuItem value="" disabled sx={menuItemStyles}>
          {placeholder}
        </MenuItem>
        {reasons.map((reason) => (
          <MenuItem key={reason.value} value={reason.value} sx={menuItemStyles}>
            {reason.label || reason.value}
          </MenuItem>
        ))}
      </Select>
      {reasonError && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
