import { useSelector } from "react-redux";
import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import {
  GetNearbyWorkersParams,
  NearbyWorkerSearchResult,
  useGetNearbyWorkers,
} from "./useGetNearbyWorkers";
import { isDefined } from "@clipboard-health/util-ts";
import { UseModalState } from "@clipboard-health/ui-react";
import { SessionType } from "src/modules/interface";
import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "@src/constants/firebaseEvents";
import { NearbyWorker, NearbyWorkerResult } from "./NearbyWorkerResult";
import { useDebouncedValue } from "@src/appV2/lib/utils/useDebouncedValue";
import {
  useGetWorkerDetailsAtWorkplace,
  WorkerCategory,
  WorkerDetailsAtWorkplaceParams,
} from "@src/appV2/Professionals/api/useGetWorkerDetailsAtWorkplace";

export interface ShiftInviteNearbyWorkersProps {
  setSelectedWorker: (worker: NearbyWorkerSearchResult) => void;
  workerType: string;
  setActiveStep: (step: number) => void;
  selectedWorker: NearbyWorkerSearchResult | undefined;
  modalState: UseModalState;
  onClickNext: () => void;
}

export function ShiftInviteNearbyWorkers(props: ShiftInviteNearbyWorkersProps) {
  const { setSelectedWorker, workerType, setActiveStep, selectedWorker, modalState, onClickNext } =
    props;
  const [value, setValue] = useState<NearbyWorkerSearchResult>();
  const [inputValue, setInputValue] = useState<string>("");
  const { profile, user, admin } = useSelector((state: SessionType) => state.session);
  const workplaceId = profile?.userId;
  const [debouncedInputValue] = useDebouncedValue(inputValue);

  const nearbyWorkersQuery = useMemo<GetNearbyWorkersParams>(
    () => ({
      workplaceId,
      searchKey: debouncedInputValue,
      workerType,
    }),
    [workplaceId, debouncedInputValue, workerType]
  );
  const { data: shiftInviteNearbyWorkers, isLoading } = useGetNearbyWorkers(nearbyWorkersQuery);

  const topWorkplaceWorkersQuery = useMemo<WorkerDetailsAtWorkplaceParams>(
    () => ({
      category: WorkerCategory.ALL_WORKERS,
      facilityId: workplaceId,
      search: debouncedInputValue,
      sort: "rank",
      workerType,
      pageSize: 10,
      include: ["ranking"],
      fields: {
        exclusion: "id",
        ranking: "id,rank,isOnATeam",
      },
    }),
    [workplaceId, workerType, debouncedInputValue]
  );
  const { data: topWorkerData } = useGetWorkerDetailsAtWorkplace(topWorkplaceWorkersQuery);

  const inviteWorkersList = useMemo<NearbyWorker[]>(() => {
    const ATeamWorkers = topWorkerData?.workers
      .filter((w) => w.ranking?.isOnATeam)
      .map<NearbyWorker>((w) => ({
        fullName: w.name,
        isOnATeam: true,
        phoneNumber: w.phone,
        workerId: w.userId,
      }));
    const filteredNearbyWorkers = shiftInviteNearbyWorkers?.filter(
      (worker) => !ATeamWorkers?.some((ATeamWorker) => ATeamWorker.workerId === worker.workerId)
    );
    return [...(ATeamWorkers ?? []), ...(filteredNearbyWorkers ?? [])].slice(0, 10);
  }, [topWorkerData?.workers, shiftInviteNearbyWorkers]);

  const segmentProperties = {
    workplaceId,
    workerType,
    ...(admin ? { adminUserId: user?._id } : { workplaceUserId: user?._id }),
    ...(selectedWorker ?? {}),
  };

  return (
    <>
      <Autocomplete
        id="get-nearby-workers"
        getOptionLabel={(option) => (typeof option === "string" ? option : option.fullName)}
        getOptionKey={(option) => (typeof option === "string" ? option : option.workerId)}
        filterOptions={(options) => options}
        options={inviteWorkersList}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value ?? null}
        isOptionEqualToValue={(option, valueSelected) => option.workerId === valueSelected.workerId}
        noOptionsText={inputValue ? "No Professional found" : "Type to search for Professionals"}
        loadingText={
          inputValue ? "Searching for Professionals" : "Type to search for Professionals"
        }
        loading={isLoading}
        onChange={(_event, newValue) => {
          if (!isDefined(newValue)) {
            return;
          }
          setValue(newValue);
          setSelectedWorker(newValue);
        }}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue?.trim());
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search for a Professional" fullWidth value={value} />
        )}
        renderOption={(renderProps, nearbyWorker) => {
          return <NearbyWorkerResult renderProps={renderProps} nearbyWorker={nearbyWorker} />;
        }}
      />
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button
          onClick={() => {
            logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
              action: "MODAL_CLOSE",
              ...segmentProperties,
            });
            modalState.closeModal();
          }}
          variant="outlined"
          startIcon={<ArrowBackIosNewOutlinedIcon />}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            setActiveStep(1);
            logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
              action: "WORKER_SELECTED",
              ...segmentProperties,
            });
            onClickNext();
          }}
          variant="contained"
          endIcon={<ArrowForwardIosOutlinedIcon />}
          disabled={!isDefined(selectedWorker)}
        >
          Next
        </Button>
      </Stack>
    </>
  );
}
