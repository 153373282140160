import { FormInstance } from "antd";
import { WorkerWithLastShift } from "src/api/workers";
import { ExclusionForList } from "src/api/workerReview";
import {
  doesRatingMakeWorkerFavorite,
  getDNRAction,
  getNameFromLegacyQuality,
  workerReviewRatingQualityOptions,
} from "src/components/WorkerRatingPerformanceModal/helpers";
import { Banner } from "src/designsystem/Banner/Banner";
import { getFirstLetterOfName, getFirstName } from "src/utils/strings";
import { DnrAction } from "../WorkerRatingPerformanceModal/workerReview.types";
import {
  FormItem,
  FormLabel,
  FormTitle,
  LastWorkedLabel,
  RatingTag,
  ReviewVisibilityInfoText,
  StyledCheckbox,
  StyledForm,
  StyledTextArea,
  Tags,
  WorkerAvatar,
  WorkerInfo,
  WorkerRating,
} from "./RatingPerformanceForm.styles";
import {
  formatShiftDate,
  getAdditionalFeedbackPlaceholder,
  getQualityTitle,
} from "src/components/WorkerRatingPerformanceModal/utils";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import {
  DnrSubmissionFormComponent,
  DnrSubmissionFormComponentRef,
} from "@src/appV2/Worker/Exclusion/DnrSubmissionFormComponent";
import { useRef, useState } from "react";
import { useDnrConfig } from "@src/appV2/Worker/Exclusion/helpers";

export type Rating = Partial<{
  id: string;
  shiftId: string;
  rating: number;
  qualities: string[];
  additionalFeedback: string;
  dnrWorker: boolean;
  dnrReason: string;
  lastEditedBy: string;
  updatedAt: string;
  severityResponse?: string;
}>;

// Extend the FormInstance type to include our custom validateForm method
interface ExtendedFormInstance extends FormInstance<any> {
  validateForm?: () => Promise<boolean>;
}

interface RatingPerformanceFormProps {
  worker: {
    avatarUrl: string;
    name: string;
  };
  lastShift: WorkerWithLastShift["lastShift"];
  rating?: Rating;
  exclusion?: ExclusionForList;
  onRatingChanged: (rating: Rating) => void;
  timezone: string;
  form: ExtendedFormInstance;
}

export function RatingPerformanceForm({
  worker,
  lastShift,
  timezone,
  rating,
  exclusion,
  form,
  onRatingChanged,
}: RatingPerformanceFormProps): JSX.Element {
  const ratingQualitiesOptions = useCbhFlag(CbhFeatureFlag.RATING_QUALITIES_OPTIONS, {
    defaultValue: {},
  });
  const qualityOptions = workerReviewRatingQualityOptions(rating?.rating, ratingQualitiesOptions);
  const showRemoveDnr = doesRatingMakeWorkerFavorite(rating?.rating) && !!exclusion;
  const dnrAction = getDNRAction(rating?.dnrWorker, exclusion);
  const isRequiredAdditionalFeedback =
    useCbhFlag(CbhFeatureFlag.MAKE_RATING_FEEDBACK_REQUIRED, {
      defaultValue: false,
    }) || rating?.dnrWorker;
  const dnrFeedbackRef = useRef<DnrSubmissionFormComponentRef>(null);
  const dnrConfig = useDnrConfig();
  const [minFeedbackLength, setMinFeedbackLength] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleReasonSelect = (dnrCharacterFloor: number) => {
    setMinFeedbackLength(dnrCharacterFloor);
  };

  const validateForm = async () => {
    setFormSubmitted(true);

    // Track validation results
    let antFormValid = true;
    let dnrComponentValid = true;

    // Validate Ant Design form fields
    try {
      await form.validateFields(["rating", "additionalFeedback", "qualities"]);
    } catch (error) {
      antFormValid = false;
    }

    // Validate DNR component if applicable
    if (rating?.rating === 1 && rating.dnrWorker) {
      dnrComponentValid = dnrFeedbackRef.current?.validate() || false;
    }

    // Return true only if both validations pass
    return antFormValid && dnrComponentValid;
  };

  form.validateForm = validateForm;

  const handleRatingChanged = (value: number) => {
    // when switching rating, we want to clear all form's error
    // since antd's form doesn't provide a way to clear form's error
    // we work around by reseting form and set back fields manually
    form.resetFields();
    onRatingChanged({
      ...rating,
      rating: value,
      // undefined means we ingore dnr action, not the same as false (which means not dnr a worker)
      dnrWorker: value === 1 ? true : undefined,
      qualities: [],
      additionalFeedback: "",
      dnrReason: undefined,
    });
  };

  const handleQualitiesChanged = (quality: string, isAdded: boolean) => {
    onRatingChanged({
      qualities: isAdded
        ? [...(rating?.qualities ?? []), quality]
        : rating?.qualities?.filter((q) => q !== quality),
    });
  };

  // Add a handler for the DNR checkbox change
  const handleDnrWorkerChanged = (checked: boolean) => {
    // When DNR is checked, we'll use the additionalFeedback in the DNR component
    // So we can clear it from the main form
    if (checked) {
      onRatingChanged({
        dnrWorker: checked,
        // Keep the existing feedback so it can be used in the DNR component
      });
    } else {
      onRatingChanged({ dnrWorker: checked });
    }
  };

  return (
    <StyledForm
      layout="vertical"
      form={form}
      requiredMark={false}
      data-testid="rating-performance-form"
    >
      <WorkerInfo>
        <WorkerAvatar src={worker.avatarUrl} alt={worker.name}>
          {getFirstLetterOfName(worker.name)}
        </WorkerAvatar>
        <FormTitle>
          {worker.name}
          {lastShift !== undefined ? `, ${lastShift.agentReq}` : undefined}
        </FormTitle>
        {lastShift !== undefined && (
          <LastWorkedLabel>
            Last worked on {formatShiftDate(lastShift.start, lastShift.end, timezone)}
          </LastWorkedLabel>
        )}
        <FormTitle bold>
          How would you rate {getFirstName(worker.name)}'s overall performance?
        </FormTitle>

        <ReviewVisibilityInfoText>
          Your 4+ star rating reviews will be visible to the professional you are rating.
        </ReviewVisibilityInfoText>

        <FormItem
          name="rating"
          rules={[
            {
              required: true,
              message: "Please select a star rating",
            },
          ]}
        >
          <WorkerRating value={rating?.rating} allowClear={false} onChange={handleRatingChanged} />
        </FormItem>
      </WorkerInfo>
      {rating !== undefined && qualityOptions.length > 0 && (
        <FormItem
          label={<FormLabel>{getQualityTitle(rating.rating)}</FormLabel>}
          name="qualities"
          rules={[
            {
              required: true,
              message: "Please select one or more qualities to proceed.",
            },
          ]}
        >
          <Tags>
            {/*
              since we completely replace old with new qualities, and we
              don't want to loss those information (without displaying old qualities in UI)
              we have this extra logic to show qualities which are not
              in our current qualities set but doesn't allow users to reselect it
            */}
            {rating.qualities
              ?.filter((quality) => !qualityOptions.includes(quality))
              .map((quality) => (
                <RatingTag
                  key={quality}
                  checked={rating.qualities?.includes(quality) ?? false}
                  onChange={(value) => handleQualitiesChanged(quality, value)}
                >
                  {getNameFromLegacyQuality(quality)}
                </RatingTag>
              ))}
            {qualityOptions.map((quality) => (
              <RatingTag
                key={quality}
                checked={rating.qualities?.includes(quality) ?? false}
                onChange={(value) => handleQualitiesChanged(quality, value)}
              >
                {quality}
              </RatingTag>
            ))}
          </Tags>
        </FormItem>
      )}
      {rating?.rating !== undefined && (
        <div>
          {rating.rating === 1 && (
            <StyledCheckbox
              checked={rating.dnrWorker}
              onChange={(e) => handleDnrWorkerChanged(e.target.checked)}
              data-testid="dnr-worker-checkbox"
            >
              Do not let this professional return to my workplace
            </StyledCheckbox>
          )}

          {/* Hide the additional feedback section when DNR is checked */}
          {!(rating.rating === 1 && rating.dnrWorker) && (
            <FormItem
              name="additionalFeedback"
              label={
                <FormLabel>
                  Why did you choose this rating?
                  {isRequiredAdditionalFeedback ? "" : " (optional)"}
                </FormLabel>
              }
              rules={[
                {
                  required: isRequiredAdditionalFeedback && !rating.dnrWorker,
                  message: `Additional feedback is required${
                    rating.dnrWorker ? " when blocking a worker" : ""
                  }.`,
                },
              ]}
              style={{ marginTop: rating.rating === 1 ? "12px" : "0" }}
            >
              <StyledTextArea
                value={rating.additionalFeedback}
                placeholder={getAdditionalFeedbackPlaceholder(rating.rating)}
                data-testid="additional-feedback-textarea"
                onChange={(e) => onRatingChanged({ additionalFeedback: e.target.value })}
              />
            </FormItem>
          )}
          {rating.rating === 1 && rating.dnrWorker && (
            <FormItem
              name="dnrReason"
              rules={[]}
              style={{ marginTop: 16 }}
              validateStatus=""
              help=""
            >
              <DnrSubmissionFormComponent
                ref={dnrFeedbackRef}
                value={rating.dnrReason}
                onChange={(value) => {
                  onRatingChanged({ dnrReason: value });
                  // Reset form submitted state when a reason is selected
                  if (value) {
                    setFormSubmitted(false);
                  }
                }}
                additionalFeedback={rating.additionalFeedback}
                onAdditionalFeedbackChange={(feedback) =>
                  onRatingChanged({
                    additionalFeedback: feedback,
                  })
                }
                placeholder="Select a reason for DNR"
                severityResponse={rating.severityResponse}
                onSeverityChange={(value) => onRatingChanged({ severityResponse: value })}
                error={false}
                helperText="Please select a reason for DNR"
                minFeedbackLength={minFeedbackLength}
                onReasonSelect={handleReasonSelect}
              />
            </FormItem>
          )}
        </div>
      )}
      {showRemoveDnr && rating ? (
        <Banner
          type="warning"
          description={
            <>
              This professional is currently blocked by your workplace. By submitting a{" "}
              {rating.rating} star rating for this professional, you will also be unblocking them
              from your facility.
            </>
          }
          data-testid="remove-dnr-worker-banner"
        />
      ) : (
        dnrAction &&
        dnrAction === DnrAction.DELETE && (
          <Banner
            type="warning"
            data-testid="dnr-action-banner"
            description="When you submit this feedback you will also be unblocking the worker."
          />
        )
      )}
    </StyledForm>
  );
}
