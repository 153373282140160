interface ValidateDnrFormArgs {
  value?: string;
  severityResponse?: string;
  hasSeverity: boolean;
  hasSeverityChange: boolean;
  additionalFeedback: string;
  hasAdditionalFeedbackChange: boolean;
  localMinFeedbackLength: number;
  setFormSubmitted: (submitted: boolean) => void;
  setReasonError: (error: boolean) => void;
  setSeverityError: (error: boolean) => void;
  setFeedbackError: (error: boolean) => void;
}

/**
 * Validates all DNR form fields and returns whether the form is valid
 * Sets appropriate error states for invalid fields
 */
export const validateDnrForm = ({
  value,
  severityResponse,
  hasSeverity,
  hasSeverityChange,
  additionalFeedback,
  hasAdditionalFeedbackChange,
  localMinFeedbackLength,
  setFormSubmitted,
  setReasonError,
  setSeverityError,
  setFeedbackError,
}: ValidateDnrFormArgs): boolean => {
  let isValid = true;

  // Set form as submitted when validation is called
  setFormSubmitted(true);

  // Validate reason
  if (!value) {
    setReasonError(true);
    isValid = false;
  }

  // Validate severity if it's available
  if (hasSeverity && hasSeverityChange && !severityResponse) {
    setSeverityError(true);
    isValid = false;
  }

  // Validate feedback if minimum length is required
  if (
    hasAdditionalFeedbackChange &&
    localMinFeedbackLength > 0 &&
    additionalFeedback.length < localMinFeedbackLength
  ) {
    setFeedbackError(true);
    isValid = false;
  }

  return isValid;
};
