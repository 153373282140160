import {
  ApiOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  ControlOutlined,
  FileDoneOutlined,
  IdcardOutlined,
  LoginOutlined,
  LogoutOutlined,
  MessageOutlined,
  OrderedListOutlined,
  QuestionCircleOutlined,
  ScheduleOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import RuleIcon from "@mui/icons-material/Rule";
import { Badge, Box, Stack } from "@mui/material";
import { HOME_HEALTH_CASES_PATH } from "@src/appV2/ExperimentalHomeHealth/Cases/paths";
import { HOME_HEALTH_VISIT_VERIFICATION_PATH } from "@src/appV2/ExperimentalHomeHealth/VisitVerification/paths";
import { useIsHomeHealthEnabled } from "@src/appV2/ExperimentalHomeHealth/lib/useIsHomeHealthEnabled";
import { ExtraWorkedTimeRequestMenuBadge } from "@src/appV2/ExtraWorkedTimeRequests/ExtraWorkedTimeRequestMenuBadge";
import { useExtraWorkedTimeRequests } from "@src/appV2/ExtraWorkedTimeRequests/api/useExtraWorkedTimeRequests";
import {
  ExtraWorkedTimeRequestPayer,
  ExtraWorkedTimeRequestStatus,
  ExtraWorkedTimeType,
} from "@src/appV2/ExtraWorkedTimeRequests/types";
import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "@src/constants/firebaseEvents";
import { useShowLiveMapView } from "@src/containers/app/hooks/showLiveMapView";
import { evaluateWorkplaceToWorkplaceReferrals } from "@src/containers/workplaceReferAndEarn/utils";
import { useFacilityOnboardingFlag } from "@src/hooks/useFacilityOnboardingFlag";
import { MapIcon } from "@src/icons/map";
import { getLocation } from "@src/utils/routes";
import { Menu, Modal } from "antd";
import { Location, createPath } from "history";
import { useFlags } from "launchdarkly-react-client-sdk";
import { includes } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { EMPLOYEE_API_ROLES } from "@src/appV2/Employee/constants";
import { EMPLOYEE_ROLES } from "@src/appV2/Employee/constants";
import {
  FACILITY_HELP_SUPPORT_LINK,
  FACILITY_ROLES,
  FACILITY_USER_PERMISSIONS,
} from "../../constants/facility";
import { CurrencyIcon } from "../../icons/currency";
import { MENU_MESSAGES } from "./constants";

import { VisitVerificationMenuItem } from "@src/appV2/ExperimentalHomeHealth/VisitVerification/VisitVerificationMenuItem";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { PlacementAccessControlStatus, Status } from "@src/appV2/FeatureFlags/CbhFeatureFlags";
import { HireProfessionalsNewBadge } from "@src/appV2/PlacementCandidates/components/HireProfessionalsNewBadge";
import { useShowContractTerms } from "./hooks/showContractTerms";
import { ReferralDollarOutlined } from "./styles";
import { useGetPlacementsAccessLevel } from "@src/appV2/Placements/useGetPlacementsAccessLevel";
import { useClipboardScore } from "../attendanceScore/useClipboardScore";
import { CHIP_COLORS } from "@src/appV2/lib/Theme/constants";
import { useIsJobInterviewsEnabled } from "@src/appV2/InterviewSettings/useIsJobInterviewsEnabled";
const { SubMenu } = Menu;

const ResponsiveMenu = styled(Menu)`
  line-height: 64px !important;
  @media (max-width: 992px) {
    float: none;
    line-height: 0;
  }
`;

type AdminMenusProps = {
  roles: any[];
  isSkillsAssessmentsEnabledForUser: boolean;
};

const adminMenus = ({ roles, isSkillsAssessmentsEnabledForUser }: AdminMenusProps) => [
  <SubMenu
    key="accounts"
    data-cbh-id="menu-item-accounts"
    title={
      <span>
        <TeamOutlined />
        <span>Accounts</span>
      </span>
    }
  >
    <Menu.Item key="agents" data-cbh-id="menu-item-agents">
      <Link to={getLocation("adminAccountsAgents")}>HCPs</Link>
    </Menu.Item>
    <Menu.Item key="facilities" data-cbh-id="menu-item-facilities">
      <Link to={getLocation("adminAccountsFacilities")}>Workplaces</Link>
    </Menu.Item>
    {roles.includes(EMPLOYEE_ROLES.ADMINISTRATIVE) && (
      <Menu.Item key="employees" data-cbh-id="menu-item-employees">
        <Link to={getLocation("adminAccountsEmployees")}>Employees</Link>
      </Menu.Item>
    )}
    {(roles.includes(EMPLOYEE_ROLES.DOCUMENTS) ||
      roles.includes(EMPLOYEE_ROLES.DOCUMENT_CREATION)) && (
      <Menu.Item key="requirements" data-cbh-id="menu-item-requirements">
        <Link to={getLocation("adminAccountsRequirement")}>Requirements</Link>
      </Menu.Item>
    )}
    <Menu.Item key="locations" data-cbh-id="menu-item-locations">
      <Link to={getLocation("adminAccountsLocations")}>Locations</Link>
    </Menu.Item>
    <Menu.Item key="changelog" data-cbh-id="menu-item-changelog">
      <Link to={getLocation("adminAccountsChangeLog")}>Change Log</Link>
    </Menu.Item>
    <Menu.Item key="exclusions" data-cbh-id="menu-item-exclusions">
      <Link to={getLocation("adminAccountsExclusions")}>DNRs</Link>
    </Menu.Item>
  </SubMenu>,
  <Menu.Item key="shifts" data-cbh-id="menu-item-shifts">
    <Link to={getLocation("adminDashboard")}>
      <span>
        <ScheduleOutlined />
        <span>Shifts</span>
      </span>
    </Link>
  </Menu.Item>,
  ...(roles.includes(EMPLOYEE_ROLES.ADMINISTRATIVE) ||
  roles.includes(EMPLOYEE_API_ROLES.INVOICE_EDIT)
    ? [
        <SubMenu
          key="Invoice"
          data-cbh-id="menu-item-invoice"
          title={
            <span>
              <FileDoneOutlined />
              <span>Invoice</span>
            </span>
          }
        >
          <Menu.Item key="byperiod" data-cbh-id="menu-item-invoice-byperiod">
            <Link to={getLocation("adminAccountsInvocingByPeriod")}>By Period</Link>
          </Menu.Item>
          <Menu.Item key="bynumber" data-cbh-id="menu-item-invoice-bynumber">
            <Link to={getLocation("adminAccountsInvocingByNumber")}>By Number</Link>
          </Menu.Item>

          <Menu.Item key="post-invoice-disputes" data-cbh-id="post-invoice-disputes">
            <Link to={getLocation("adminAccountsPostInvoiceDisputes")}>Post-Invoice Disputes</Link>
          </Menu.Item>

          <Menu.Item key="pre-invoice-disputes" data-cbh-id="pre-invoice-disputes">
            <Link to={getLocation("adminAccountsPreInvoiceDisputes")}>Pre-Invoice Disputes</Link>
          </Menu.Item>
        </SubMenu>,
      ]
    : roles.includes(EMPLOYEE_ROLES.BILLING)
    ? [
        <SubMenu
          key="Invoice"
          data-cbh-id="menu-item-invoice"
          title={
            <span>
              <FileDoneOutlined />
              <span>Invoice</span>
            </span>
          }
        >
          <Menu.Item key="bynumber" data-cbh-id="menu-item-invoice-bynumber">
            <Link to={getLocation("adminAccountsInvocingByNumber")}>By Number</Link>
          </Menu.Item>
        </SubMenu>,
      ]
    : []),

  <SubMenu
    key="payroll-menu"
    data-cbh-id="menu-item-payroll"
    title={
      <span>
        <CurrencyIcon />
        <span>Payroll</span>
      </span>
    }
  >
    <Menu.Item key="proof" data-cbh-id="menu-item-proof-of-earnings-agent">
      <Link to={getLocation("agentProof")}>Proof of Earnings</Link>
    </Menu.Item>
  </SubMenu>,
  <SubMenu
    key="logs"
    data-cbh-id="menu-item-logs"
    title={
      <span>
        <ApiOutlined />
        <span>Logs</span>
      </span>
    }
  >
    <Menu.Item key="shift" data-cbh-id="menu-item-shift-logs">
      <Link to={getLocation("adminLogsShift")}>Shifts</Link>
    </Menu.Item>
  </SubMenu>,
  ...(roles.some((role) =>
    [EMPLOYEE_ROLES.ADMINISTRATIVE, EMPLOYEE_ROLES.NFC_REQUESTS].includes(role)
  )
    ? [
        <Menu.Item
          key="timeclockrequests"
          data-cbh-id="menu-item-timeclockrequests"
          data-testid="menu-item-timeclockrequests"
        >
          <Link to={getLocation("adminTimeclock")}>
            <span>
              <ClockCircleOutlined />
              <span>Timeclock Requests</span>
            </span>
          </Link>
        </Menu.Item>,
      ]
    : []),
  ...(roles.includes(EMPLOYEE_ROLES.SHIFT_PRICING)
    ? [
        <Menu.Item key="shiftPayments-deprecated" data-cbh-id="menu-item-shiftpayments">
          <Link to={getLocation("adminShiftPaymentsPayRates")}>
            <span>
              <CurrencyIcon />
              <span>Shift Payments</span>
            </span>
          </Link>
        </Menu.Item>,
      ]
    : []),
  <Menu.Item key="urgentShiftsNcns" data-cbh-id="menu-item-urgentShiftsNcns">
    <Link to={getLocation("adminUrgentShiftNcns")}>
      <span>
        <ScheduleOutlined />
        <span>No shows</span>
      </span>
    </Link>
  </Menu.Item>,
  ...(roles.includes(EMPLOYEE_ROLES.MANAGE_WORKPLACE_TYPES) ||
  roles.includes(EMPLOYEE_ROLES.MANAGE_QUALIFICATIONS) ||
  isSkillsAssessmentsEnabledForUser
    ? [
        <SubMenu
          key="staffing"
          data-cbh-id="menu-item-staffing"
          title={
            <span>
              <SolutionOutlined />
              <span>Staffing</span>
            </span>
          }
        >
          {roles.includes(EMPLOYEE_ROLES.MANAGE_QUALIFICATIONS) && (
            <Menu.Item key="qualifications" data-cbh-id="menu-item-staffing-qualifications">
              <Link to={getLocation("adminManageQualifications")}>Qualifications</Link>
            </Menu.Item>
          )}
          {roles.includes(EMPLOYEE_ROLES.MANAGE_WORKPLACE_TYPES) && (
            <Menu.Item key="workplace-types" data-cbh-id="menu-item-staffing-workplace-types">
              <Link to={getLocation("adminManageWorkplaceTypes")}>Workplace types</Link>
            </Menu.Item>
          )}
          {isSkillsAssessmentsEnabledForUser && (
            <Menu.Item key="skills-assessments" data-cbh-id="menu-item-staffing-skills-assessments">
              <Link to={getLocation("adminManageSkillsAssessments")}>Skill Assessments</Link>
            </Menu.Item>
          )}
        </SubMenu>,
      ]
    : []),
];

const agentMenus = (configuration, scoreText: string) => [
  <Menu.Item key="dashboard" data-cbh-id="menu-item-dashboard">
    <Link to={getLocation("agentDashboard")}>
      <CalendarOutlined />
      <span>Calendar</span>
    </Link>
  </Menu.Item>,

  <SubMenu
    key="list-payroll"
    data-cbh-id="menu-item-agent-list-payroll"
    title={
      <span>
        <CurrencyIcon />
        <span>Payroll</span>
      </span>
    }
  >
    {configuration.isPayrollViewEnabled && (
      <Menu.Item
        key="payroll"
        data-cbh-id="menu-item-agent-payroll"
        data-testid="menu-item-agent-payroll"
      >
        <Link to={getLocation("agentPayroll")}>Payroll</Link>
      </Menu.Item>
    )}
    {configuration.isStripeTransfersViewEnabled && (
      <Menu.Item key="stripe-transfers" data-cbh-id="menu-item-agent-stripe-transfers">
        <Link to={getLocation("agentStripeTransfers")}>Stripe Transfers</Link>
      </Menu.Item>
    )}
    <Menu.Item key="stripe-payouts" data-cbh-id="menu-item-agent-stripe-payouts">
      <Link to={getLocation("agentStripePayouts")}>Stripe Payouts</Link>
    </Menu.Item>
  </SubMenu>,

  <Menu.Item key="attendance" data-cbh-id="menu-item-agent-attendance">
    <Link to={getLocation("agentAttendance")}>
      <CalendarOutlined />
      <span>{scoreText}</span>
    </Link>
  </Menu.Item>,

  <Menu.Item key="placement" data-cbh-id="menu-item-agent-placement">
    <Link to={getLocation("agentPlacement")}>
      <IdcardOutlined />
      <span>Placement</span>
    </Link>
  </Menu.Item>,
];

const logHelpArticlesClick = () => {
  logEvent(HCF_USER_EVENTS.HELP_CENTER_OPENED);
};

const facilityMenus = ({
  roles,
  permissions,
  admin,
  userProfileRenderer,
  ldFlags,
  unreadChannelCount,
  profile,
  shouldShowContractTerms,
  shouldShowLiveMapView,
  shouldShowWorkerMenu,
  location,
  shouldShowFacilityOnboarding,
  isWorkplaceToWorkplaceReferralsEnabled,
  logMenuItemClicked,
  pendingExtraWorkedTimeRequestCount,
  isBillingSubMenuExpanded,
  setIsBillingSubMenuExpanded,
  isHomeHealthEnabled = false,
  isPlacementsEnabled = false,
  isJobInterviewsEnabled = false,
  isHomeHealthHiddenMenuItemsEnabled = true,
  isPlacementsLandingPageEnabled = false,
}) => {
  const { minimumWorkplaceCreationDate } = ldFlags?.[CbhFeatureFlag.HCF_ONBOARDING] ?? {};

  const workplaceInExperiment =
    minimumWorkplaceCreationDate && profile?.createdAt
      ? new Date(profile.createdAt) > new Date(minimumWorkplaceCreationDate)
      : false;
  const isFacilityOnboardingActive = workplaceInExperiment && shouldShowFacilityOnboarding;
  const getFacilityOnboardingSteps = profile?.onboardingSteps ?? {};
  const remainingOnboardingSteps = Object.keys(getFacilityOnboardingSteps).filter(
    (key) => getFacilityOnboardingSteps[key] === false
  ).length;

  const hasDefaultFacilityPermissions =
    [
      FACILITY_ROLES.ADMIN,
      FACILITY_ROLES.SHIFT_MANAGEMENT,
      FACILITY_ROLES.DOCUMENTS,
      FACILITY_ROLES.INVOICES,
    ].some((role) => includes(roles, role)) || admin;
  const hasPermissionToRecruit =
    includes(roles, FACILITY_ROLES.RECRUITMENT) ||
    includes(roles, FACILITY_ROLES.ADMIN) ||
    includes(roles, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
    admin;
  const hasPermissionToAccessWorkerDirectory =
    includes(roles, FACILITY_ROLES.ADMIN) ||
    includes(roles, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
    includes(roles, FACILITY_ROLES.RECRUITMENT) ||
    admin;

  const isFacilityRulesAiEnabled =
    ldFlags?.[CbhFeatureFlag.ROLLOUT_QUIZ_V2_FACILITY_RULES_AI] ?? false;

  const aTeamConfig = ldFlags?.[CbhFeatureFlag.A_TEAM_CONFIG] ?? { status: Status.OFF };
  const workerMenuName =
    aTeamConfig.status === Status.ON
      ? MENU_MESSAGES.MY_PROFESSIONALS
      : MENU_MESSAGES.FAVORITE_BOOKED;
  const hasPermissionToAccessFacilitySettingsPage = includes(roles, FACILITY_ROLES.ADMIN) || admin;

  const MENU_ITEM_TEST_IDS = {
    LIVE_WORKER_ETA_MENU_ITEM: "menu-item-live-worker-eta",
    HELP_CENTER_MENU_ITEM: "menu-item-help-center",
    HELP_CENTER_MENU_ITEM_LINK: "menu-item-help-center-link",
    FACILITY_ONBOARDING: "menu-item-facility-onboarding",
    REFER_AND_EARN: "menu-item-refer-and-earn",
    FACILITY_RULES_AI: "menu-item-facility-rules-ai",
  };

  const hasBillingRights =
    admin || includes(roles, FACILITY_ROLES.ADMIN) || includes(roles, FACILITY_ROLES.INVOICES);

  const hasManageInvoiceRights =
    hasBillingRights || includes(permissions, FACILITY_USER_PERMISSIONS.MANAGE_INVOICES);

  const hasViewUpcomingChargesRights =
    hasBillingRights ||
    includes(permissions, FACILITY_USER_PERMISSIONS.ACCESS_UPCOMING_CHARGES) ||
    includes(roles, FACILITY_ROLES.SHIFT_MANAGEMENT);

  const hasViewContractTermsRights =
    hasBillingRights || includes(permissions, FACILITY_USER_PERMISSIONS.ACCESS_CONTRACT_TERMS);

  const hideMenuItemsForHomeHealth = isHomeHealthEnabled && isHomeHealthHiddenMenuItemsEnabled;
  const hideBrowserWorkers = ldFlags?.[CbhFeatureFlag.HIDE_BROWSER_WORKERS];

  return [
    isFacilityOnboardingActive && (
      <Menu.Item
        key="getting-started"
        data-cbh-id={MENU_ITEM_TEST_IDS.FACILITY_ONBOARDING}
        data-testid={MENU_ITEM_TEST_IDS.FACILITY_ONBOARDING}
        className={
          location?.pathname === getLocation("facilityOnboarding").pathname
            ? "ant-menu-item-selected" // This class is the active class used by antd to activate the route.
            : ""
        }
        onClick={() => logMenuItemClicked(MENU_MESSAGES.GETTING_STARTED, location)}
      >
        <Link to={getLocation("facilityOnboarding")}>
          <OrderedListOutlined />
          <span>
            {MENU_MESSAGES.GETTING_STARTED}
            {!!remainingOnboardingSteps && (
              // FIXME: Badge should wrap around the span, but the current MUI support is not compatible with the way we're using badges
              <Badge
                badgeContent={remainingOnboardingSteps}
                color="error"
                sx={{ paddingLeft: 2 }}
              />
            )}
          </span>
        </Link>
      </Menu.Item>
    ),
    hasDefaultFacilityPermissions && !hideMenuItemsForHomeHealth && (
      <Menu.Item
        key="dashboard"
        data-cbh-id="menu-item-dashboard"
        data-testid="menu-item-dashboard"
        onClick={() => logMenuItemClicked(MENU_MESSAGES.CALENDAR, location)}
      >
        <Link to={getLocation("facilityDashboard")}>
          <CalendarOutlined />
          <span>{MENU_MESSAGES.CALENDAR}</span>
        </Link>
      </Menu.Item>
    ),
    (includes(roles, FACILITY_ROLES.ADMIN) ||
      includes(roles, FACILITY_ROLES.DOCUMENTS) ||
      includes(roles, FACILITY_ROLES.SHIFT_MANAGEMENT)) && (
      <Menu.Item
        key="chat"
        data-cbh-id="menu-item-chat"
        data-testid="menu-item-chat"
        onClick={() => logMenuItemClicked(MENU_MESSAGES.CHAT, location)}
      >
        <Link to={getLocation("facilityChat")}>
          <MessageOutlined />
          <span>
            {MENU_MESSAGES.CHAT}{" "}
            {/* FIXME: Badge should wrap around the span, but the current MUI support is not compatible with the way we're using badges */}
            <Badge badgeContent={unreadChannelCount} color="error" sx={{ paddingLeft: 2 }} />
          </span>
        </Link>
      </Menu.Item>
    ),
    shouldShowWorkerMenu && (
      <SubMenu
        key="workers"
        data-cbh-id="menu-item-workers"
        data-testid="menu-item-workers"
        onTitleClick={() => {
          logMenuItemClicked(MENU_MESSAGES.WORKERS, location);
        }}
        title={
          <span>
            <IdcardOutlined />
            <span>{MENU_MESSAGES.WORKERS}</span>
          </span>
        }
      >
        <Menu.Item
          key="favorite-workers"
          data-cbh-id="menu-item-favorite-workers"
          data-testid="menu-item-favorite-workers"
          className={
            location?.pathname === getLocation("workplaceWorkers").pathname
              ? "ant-menu-item-selected" // This class is the active class used by antd to activate the route.
              : ""
          }
          onClick={() => logMenuItemClicked(workerMenuName, location)}
        >
          <Link to={getLocation("workplaceWorkers")}>
            <span>{workerMenuName}</span>
          </Link>
        </Menu.Item>

        {hasPermissionToAccessWorkerDirectory && !isHomeHealthEnabled && !hideBrowserWorkers && (
          <Menu.Item
            key="browse-workers"
            data-cbh-id="menu-item-browse-workers"
            data-testid="menu-item-browse-workers"
            className={
              location?.pathname === getLocation("workersDirectory").pathname
                ? "ant-menu-item-selected" // This class is the active class used by antd to activate the route.
                : ""
            }
            onClick={() => logMenuItemClicked(MENU_MESSAGES.BROWSE_PROFESSIONALS, location)}
          >
            <Link to={getLocation("workersDirectory")}>
              <span>{MENU_MESSAGES.BROWSE_PROFESSIONALS}</span>
            </Link>
          </Menu.Item>
        )}
      </SubMenu>
    ),
    hasPermissionToRecruit && (isPlacementsEnabled || isPlacementsLandingPageEnabled) && (
      <SubMenu
        key="placement-section"
        data-cbh-id="menu-item-placement-section"
        data-testid="menu-item-placement-section"
        onTitleClick={() => {
          logMenuItemClicked(MENU_MESSAGES.PLACEMENT_SECTION, location);
        }}
        title={
          <span>
            <ShoppingOutlined style={{ color: CHIP_COLORS.info.color }} />
            <span>{MENU_MESSAGES.PLACEMENT_SECTION}</span>
          </span>
        }
      >
        {isPlacementsLandingPageEnabled && (
          <Menu.Item
            key="placement-landing-page"
            className={
              location?.pathname === getLocation("jobsLandingPage").pathname
                ? "ant-menu-item-selected"
                : ""
            }
            onClick={() => logMenuItemClicked(MENU_MESSAGES.JOBS_LANDING_PAGE, location)}
          >
            <Link to={getLocation("jobsLandingPage")}>{MENU_MESSAGES.JOBS_LANDING_PAGE}</Link>
          </Menu.Item>
        )}
        {isPlacementsEnabled && (
          <>
            <Menu.Item
              key="manage-jobs"
              className={
                location?.pathname === getLocation("manageJobs").pathname
                  ? "ant-menu-item-selected"
                  : ""
              }
              onClick={() => logMenuItemClicked(MENU_MESSAGES.MANAGE_JOBS, location)}
            >
              <Link to={getLocation("manageJobs")}>{MENU_MESSAGES.MANAGE_JOBS}</Link>
            </Menu.Item>
            <Menu.Item
              key="hire-professionals"
              className={
                location?.pathname === getLocation("workplaceHireProfessionals").pathname
                  ? "ant-menu-item-selected"
                  : ""
              }
              onClick={() => logMenuItemClicked(MENU_MESSAGES.HIRE_PROFESSIONALS, location)}
            >
              <Link
                to={getLocation("workplaceHireProfessionals", {
                  queryParams: { source: "sidenav" },
                })}
              >
                <span>{MENU_MESSAGES.HIRE_PROFESSIONALS}</span>
                <HireProfessionalsNewBadge customSx={{ paddingLeft: 2.5 }} />
              </Link>
            </Menu.Item>

            {isJobInterviewsEnabled && (
              <Menu.Item
                key="interview-settings"
                className={
                  location?.pathname === getLocation("interviewSettings").pathname
                    ? "ant-menu-item-selected"
                    : ""
                }
                onClick={() => logMenuItemClicked(MENU_MESSAGES.INTERVIEW_SETTINGS, location)}
              >
                <Link to={getLocation("interviewSettings")}>
                  {MENU_MESSAGES.INTERVIEW_SETTINGS}
                </Link>
              </Menu.Item>
            )}
          </>
        )}
      </SubMenu>
    ),
    includes(roles, FACILITY_ROLES.RECRUITMENT) && (
      <Menu.Item
        key="recruiter-chat"
        data-cbh-id="menu-item-recruiter-chat"
        data-testid="menu-item-recruiter-chat"
        onClick={() => logMenuItemClicked(MENU_MESSAGES.CHAT, location)}
      >
        <Link to={getLocation("facilityChat")}>
          <MessageOutlined />
          <span>
            {MENU_MESSAGES.CHAT}{" "}
            {/* FIXME: Badge should wrap around the span, but the current MUI support is not compatible with the way we're using badges */}
            <Badge badgeContent={unreadChannelCount} color="error" sx={{ paddingLeft: 2 }} />
          </span>
        </Link>
      </Menu.Item>
    ),
    shouldShowLiveMapView && hasDefaultFacilityPermissions && !isHomeHealthEnabled && (
      <Menu.Item
        key="live-worker-eta"
        data-cbh-id={MENU_ITEM_TEST_IDS.LIVE_WORKER_ETA_MENU_ITEM}
        data-testid={MENU_ITEM_TEST_IDS.LIVE_WORKER_ETA_MENU_ITEM}
        onClick={() => logMenuItemClicked(MENU_MESSAGES.LIVE_WORKER_ETA, location)}
      >
        <Link to={getLocation("facilityLiveWorkerETA")}>
          <MapIcon />
          <span>{MENU_MESSAGES.LIVE_WORKER_ETA}</span>
        </Link>
      </Menu.Item>
    ),
    isFacilityRulesAiEnabled && (
      <Menu.Item
        key="worker-instructions"
        data-cbh-id={MENU_ITEM_TEST_IDS.FACILITY_RULES_AI}
        data-testid={MENU_ITEM_TEST_IDS.FACILITY_RULES_AI}
        onClick={() => logMenuItemClicked(MENU_MESSAGES.FACILITY_RULES_AI, location)}
      >
        <Link
          to={getLocation("facilityWorkerInstructions", {
            queryParams: { click_src: "menu_item" },
          })}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {/* To make menu item compatible with antd menu collapsible, we need to add a role="img" className="anticon" to the icon */}
            <RuleIcon role="img" className="anticon" sx={{ width: 16, height: 16 }} />
            <span>{MENU_MESSAGES.FACILITY_RULES_AI}</span>
          </Stack>
        </Link>
      </Menu.Item>
    ),
    <SubMenu
      key="management"
      data-cbh-id="menu-item-management"
      onTitleClick={() => logMenuItemClicked(MENU_MESSAGES.MANAGEMENT, location)}
      title={
        <span>
          <ControlOutlined />
          <span>{MENU_MESSAGES.MANAGEMENT}</span>
        </span>
      }
    >
      {ldFlags?.[CbhFeatureFlag.MY_ACCOUNT_PAGE] && !admin && (
        <Menu.Item
          key="profile"
          data-cbh-id="menu-item-profile"
          className={
            location?.pathname.includes(getLocation("myAccount").pathname)
              ? "ant-menu-item-selected" // This class is the active class used by antd to activate the route.
              : ""
          }
          onClick={() => logMenuItemClicked(MENU_MESSAGES.MY_ACCOUNT, location)}
        >
          <Link className="ellipsis" to={getLocation("myAccount")}>
            <span>{MENU_MESSAGES.MY_ACCOUNT}</span>
          </Link>
        </Menu.Item>
      )}
      {ldFlags?.[CbhFeatureFlag.NEW_USERS_PAGE] ? (
        includes(roles, FACILITY_ROLES.ADMIN) ||
        includes(roles, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
        includes(roles, FACILITY_ROLES.INVOICES) ||
        admin ? (
          <Menu.Item
            key="users"
            data-cbh-id="menu-item-users"
            onClick={() => logMenuItemClicked(MENU_MESSAGES.TEAM_MEMBERS, location)}
          >
            <Link to={getLocation("workplaceUsers")}>{MENU_MESSAGES.TEAM_MEMBERS}</Link>
          </Menu.Item>
        ) : null
      ) : (
        <Menu.Item
          key="users"
          data-cbh-id="menu-item-users"
          onClick={() => logMenuItemClicked(MENU_MESSAGES.USERS, location)}
        >
          <Link to={getLocation("facilityUsers")}>{MENU_MESSAGES.USERS}</Link>
        </Menu.Item>
      )}
      {!isFacilityRulesAiEnabled && hasDefaultFacilityPermissions ? (
        <Menu.Item
          key="worker-instructions"
          data-cbh-id="menu-item-worker-instructions"
          onClick={() => logMenuItemClicked(MENU_MESSAGES.FACILITY_INFORMATION, location)}
        >
          <Link to={getLocation("facilityWorkerInstructions")}>
            {MENU_MESSAGES.FACILITY_INFORMATION}
          </Link>
        </Menu.Item>
      ) : null}
      {includes(roles, FACILITY_ROLES.ADMIN) && !hasPermissionToAccessFacilitySettingsPage && (
        <Menu.Item
          key="custom-settings"
          data-cbh-id="custom-settings"
          onClick={() => logMenuItemClicked(MENU_MESSAGES.SETTINGS, location)}
        >
          <Link to={getLocation("facilityTimeclockSettings")}>{MENU_MESSAGES.SETTINGS}</Link>
        </Menu.Item>
      )}
      {hasPermissionToAccessFacilitySettingsPage && (
        <Menu.Item
          key="facility-settings"
          className={
            location?.pathname === getLocation("facilitySettings").pathname
              ? "ant-menu-item-selected" // This class is the active class used by antd to activate the route.
              : ""
          }
          onClick={() => logMenuItemClicked(MENU_MESSAGES.SETTINGS, location)}
        >
          <Link
            to={getLocation("facilitySettings", {
              queryParams: { tab: "custom-settings" },
            })}
          >
            {MENU_MESSAGES.SETTINGS}
          </Link>
        </Menu.Item>
      )}
    </SubMenu>,

    (hasViewUpcomingChargesRights ||
      hasManageInvoiceRights ||
      (shouldShowContractTerms && hasViewContractTermsRights)) && (
      <SubMenu
        key="Invoice"
        data-cbh-id="menu-item-invoice"
        data-testid="menu-item-invoice"
        onTitleClick={() => {
          setIsBillingSubMenuExpanded(!isBillingSubMenuExpanded);
          logMenuItemClicked(MENU_MESSAGES.BILLING, location);
        }}
        title={
          <span>
            <FileDoneOutlined />
            <span>{MENU_MESSAGES.BILLING}</span>
            {pendingExtraWorkedTimeRequestCount > 0 && isBillingSubMenuExpanded === false && (
              <ExtraWorkedTimeRequestMenuBadge
                sx={{ paddingLeft: 2 }}
                count={pendingExtraWorkedTimeRequestCount}
              />
            )}
          </span>
        }
      >
        {hasViewUpcomingChargesRights && (
          <Menu.Item
            key="upcoming-charges"
            data-cbh-id="menu-item-invoice-upcoming-charges"
            onClick={() => logMenuItemClicked(MENU_MESSAGES.UPCOMING_CHARGES, location)}
          >
            <Link to={getLocation("facilityInvoiceUpcomingCharges")}>
              {MENU_MESSAGES.UPCOMING_CHARGES}
              {pendingExtraWorkedTimeRequestCount > 0 && (
                <ExtraWorkedTimeRequestMenuBadge
                  sx={{ paddingLeft: 2 }}
                  count={pendingExtraWorkedTimeRequestCount}
                />
              )}
            </Link>
          </Menu.Item>
        )}
        {hasManageInvoiceRights && (
          <Menu.Item
            key="history"
            data-cbh-id="menu-item-invoice-history"
            onClick={() => logMenuItemClicked(MENU_MESSAGES.INVOICE_HISTORY, location)}
          >
            <Link to={getLocation("facilityInvoiceHistory")}>{MENU_MESSAGES.INVOICE_HISTORY}</Link>
          </Menu.Item>
        )}
        {shouldShowContractTerms && hasViewContractTermsRights && !hideMenuItemsForHomeHealth && (
          <Menu.Item
            key="contract-terms"
            data-cbh-id="menu-item-contract-terms"
            onClick={() => logMenuItemClicked(MENU_MESSAGES.CONTRACT_TERMS, location)}
          >
            <Link to={getLocation("facilityInvoiceContractTerms")}>
              {MENU_MESSAGES.CONTRACT_TERMS}
            </Link>
          </Menu.Item>
        )}
      </SubMenu>
    ),
    userProfileRenderer,
    <Menu.Item
      key="help-center"
      data-cbh-id={MENU_ITEM_TEST_IDS.HELP_CENTER_MENU_ITEM}
      data-testid={MENU_ITEM_TEST_IDS.HELP_CENTER_MENU_ITEM}
      onClick={() => {
        logMenuItemClicked(MENU_MESSAGES.HELP_CENTER, location);
        logHelpArticlesClick();
      }}
    >
      <a
        href={FACILITY_HELP_SUPPORT_LINK}
        target="_blank"
        data-cbh-id={MENU_ITEM_TEST_IDS.HELP_CENTER_MENU_ITEM_LINK}
        data-testid={MENU_ITEM_TEST_IDS.HELP_CENTER_MENU_ITEM_LINK}
      >
        <QuestionCircleOutlined />
        <span>Help Center</span>
      </a>
    </Menu.Item>,
    isWorkplaceToWorkplaceReferralsEnabled === true && (
      <Menu.Item
        key="refer-and-earn"
        data-cbh-id={MENU_ITEM_TEST_IDS.REFER_AND_EARN}
        data-testid={MENU_ITEM_TEST_IDS.REFER_AND_EARN}
        onClick={() => logMenuItemClicked(MENU_MESSAGES.REFER_AND_EARN, location)}
      >
        <Link
          to={getLocation("workplaceReferAndEarn", {
            queryParams: { source: "sidenav" },
          })}
        >
          <ReferralDollarOutlined />
          <span>{MENU_MESSAGES.REFER_AND_EARN}</span>
        </Link>
      </Menu.Item>
    ),
  ];
};

export function AppMenu({
  admin,
  isAgent,
  isAdmin,
  isFacility,
  hasSession,
  className,
  mode,
  selectable,
  profile,
  logout,
  closeMenu,
  enableDefaultSelectedKeys,
  unreadChannelCount,
  emailOfLoggedInUser,
  configuration,
  ...rest
}) {
  const [selectedKey, setSelectedKey] = useState<any>();

  const {
    name,
    access: roles,
    permissions,
  } = useSelector((state: any) => state.session.user || {});
  const { user } = useSelector((state: any) => state.session);
  const isHomeHealthEnabled = useIsHomeHealthEnabled();
  const { isEnabled: isPlacementsEnabled, accessLevel: placementsAccessLevel } =
    useGetPlacementsAccessLevel();
  const isPlacementsLandingPageEnabled =
    placementsAccessLevel === PlacementAccessControlStatus.LANDING_PAGE;
  const isJobInterviewsEnabled = useIsJobInterviewsEnabled();
  const shouldShowContractTerms = useShowContractTerms(isAgent);
  const shouldShowLiveMapView = useShowLiveMapView();
  const userName = profile ? profile.name : "Home";

  const { pathname } = useLocation();
  const routeKey = pathname.split("/").pop();
  const ldFlags = useFlags();
  const { location } = useHistory();

  const isSkillsAssessmentsEnabled = useCbhFlag(CbhFeatureFlag.SKILL_ASSESSMENTS_GRADERS, {
    defaultValue: { allowedUsers: [] },
  });

  const isSkillsAssessmentsEnabledForUser = useMemo(
    () => isSkillsAssessmentsEnabled.allowedUsers.includes(user?._id),
    [isSkillsAssessmentsEnabled.allowedUsers, user?._id]
  );

  const pendingExtraWorkedTimeRequestCount = useExtraWorkedTimeRequests({
    filter: {
      type: [ExtraWorkedTimeType.BREAK],
      status: [ExtraWorkedTimeRequestStatus.PENDING],
      facilityId: profile ? [profile.userId] : undefined,
      billedTo: [ExtraWorkedTimeRequestPayer.HCF],
    },
    page: {
      size: 0,
    },
  })?.data?.meta.count;
  const [isBillingSubMenuExpanded, setIsBillingSubMenuExpanded] = useState(isFacility);

  // User with supervisor role (FACILITY_ROLES.DOCUMENT)
  // can not see the worker menu.
  const shouldShowWorkerMenu = useMemo(() => {
    return user
      ? admin ||
          includes(user.access, FACILITY_ROLES.ADMIN) ||
          includes(user.access, FACILITY_ROLES.INVOICES) ||
          includes(user.access, FACILITY_ROLES.SHIFT_MANAGEMENT)
      : false;
  }, [user, admin]);

  useEffect(() => {
    setSelectedKey(routeKey);
  }, [routeKey]);

  const isHomeHealthHiddenMenuItemsEnabled = useCbhFlag(
    CbhFeatureFlag.HOME_HEALTH_MENU_ITEMS_HIDDEN,
    {
      defaultValue: true,
    }
  );

  const shouldShowFacilityOnboarding = useFacilityOnboardingFlag(
    profile,
    includes(roles, FACILITY_ROLES.ADMIN) ||
      includes(roles, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
      admin,
    admin,
    isFacility
  );

  const isWorkplaceToWorkplaceReferralsEnabled = evaluateWorkplaceToWorkplaceReferrals(
    ldFlags,
    user,
    isFacility,
    admin
  );

  const logoutConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to logout?",
      onOk: logout,
    });
  };

  const handleOnSelect = ({ key }) => {
    setSelectedKey([key]);
  };

  const logMenuItemClicked = (menuItemName: string, previousLocation: Location) => {
    if (!admin) {
      logEvent(HCF_USER_EVENTS.SIDEBAR_MENU_ITEM_CLICKED, {
        menuItemName,
        workplaceId: profile?.userId,
        workplaceUserId: user?._id,
        previousPath: createPath(previousLocation),
      });
    }
  };

  const path =
    (isAdmin && getLocation("adminProfile")) ||
    (isAgent && getLocation("agentProfile")) ||
    (isFacility && name && getLocation("facilityProfile")) ||
    getLocation("adminProfile");

  const userProfileRenderer = !(ldFlags[CbhFeatureFlag.MY_ACCOUNT_PAGE] && isFacility && name) ? (
    <Menu.Item
      key="profile"
      data-cbh-id="menu-item-profile"
      onClick={() => logMenuItemClicked(MENU_MESSAGES.USER_PROFILE, location)}
    >
      <Link className="ellipsis" to={path}>
        {hasSession ? <UserOutlined /> : <LoginOutlined />}
        <span>{name || userName}</span>
      </Link>
    </Menu.Item>
  ) : null;

  const { scoreText } = useClipboardScore();

  return (
    <ResponsiveMenu
      theme="dark"
      mode={mode}
      selectable={selectable || false}
      className={className}
      onClick={closeMenu}
      selectedKeys={enableDefaultSelectedKeys ? selectedKey : false}
      onSelect={handleOnSelect}
      defaultOpenKeys={isFacility ? ["management", "Invoice", "workers", "placement-section"] : []}
      {...rest}
    >
      {isHomeHealthEnabled ? (
        <Menu.Item key="cases">
          <Link to={`/v2/home/${HOME_HEALTH_CASES_PATH}`}>
            <UserOutlined />
            <span>Cases</span>
          </Link>
        </Menu.Item>
      ) : null}
      {isHomeHealthEnabled ? (
        <Menu.Item key="visit-verification">
          <Link to={`/v2/home/${HOME_HEALTH_VISIT_VERIFICATION_PATH}`}>
            {/* need to move left because MUI icons differ from AntD icons (more padding) */}
            <Box marginLeft="-3px">
              <VisitVerificationMenuItem />
            </Box>
          </Link>
        </Menu.Item>
      ) : null}
      {isAdmin &&
        adminMenus({ roles: profile ? profile.roles : [], isSkillsAssessmentsEnabledForUser }).map(
          (menu) => menu
        )}
      {isAgent && agentMenus(configuration, scoreText).map((menu) => menu)}
      {isFacility
        ? facilityMenus({
            roles,
            permissions,
            admin,
            userProfileRenderer,
            ldFlags,
            unreadChannelCount,
            profile,
            shouldShowContractTerms,
            shouldShowLiveMapView,
            shouldShowWorkerMenu,
            location,
            shouldShowFacilityOnboarding,
            isWorkplaceToWorkplaceReferralsEnabled,
            logMenuItemClicked,
            pendingExtraWorkedTimeRequestCount,
            isBillingSubMenuExpanded,
            setIsBillingSubMenuExpanded,
            isHomeHealthEnabled,
            isPlacementsEnabled,
            isJobInterviewsEnabled,
            isHomeHealthHiddenMenuItemsEnabled,
            isPlacementsLandingPageEnabled,
          })
        : // user profile should appear after the help section. so it is rendered different for workplace login.
          userProfileRenderer}
      {hasSession && (
        <Menu.Item key="logout" data-cbh-id="menu-item-logout">
          {
            // eslint-disable-next-line
            <a onClick={logoutConfirm}>
              <LogoutOutlined />
              <span>Log out</span>
            </a>
          }
        </Menu.Item>
      )}
    </ResponsiveMenu>
  );
}
