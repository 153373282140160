import { ShiftInviteRequestMessage } from "@src/appV2/Chat/ShiftInviteRequestMessage";
import { SendbirdChannelMessageProps } from "./chat.types";
import { ReviewSentHomeMessage } from "./ReviewSentHomeMessage";
import { SentHomeResponseMessage } from "./SentHomeResponseMessage";
import { MessageCustomTypes } from "@src/appV2/Chat/type";
import { ShiftInviteResponseMessage } from "@src/appV2/Chat/ShiftInviteResponseMessage";
import { PlacementApplicationMessage } from "./components/PlacementApplicationMessage";
import { isDefined } from "@clipboard-health/util-ts";
import { PlacementApplicationAutoInterviewInvite } from "./components/PlacementApplicationAutoInterviewInvite";
import { PlacementApplicationInvite } from "./components/PlacementApplicationInvite";
import { EveryMessage } from "@sendbird/uikit-react/types/types";
import { CbhCustomChatMessage } from "@src/appV2/Chat/CbhCustomChatMessage";

// We are not able render this component as JSX with return type undefined as it's wrapped in an useMemo in it's source code [here](https://github.com/sendbird/sendbird-uikit-react/blob/main/src/modules/Channel/components/Message/index.tsx#L194)
export function renderSendbirdChannelMessage(props: SendbirdChannelMessageProps) {
  const { message, userId, user, currentChannel, channelUrl } = props;

  if (message.customType === MessageCustomTypes.FCM_REQUEST) {
    return (
      <ReviewSentHomeMessage message={message} facilityId={userId} facilityUserId={user?._id} />
    );
  }

  if (message.customType === MessageCustomTypes.SHIFT_INVITE_REQUEST) {
    return <ShiftInviteRequestMessage message={message} />;
  }

  if (message.customType === MessageCustomTypes.SHIFT_INVITE_RESPONSE) {
    return <ShiftInviteResponseMessage message={message} />;
  }

  if (
    [
      MessageCustomTypes.SHIFT_TIME_PROPOSAL_CREATED,
      MessageCustomTypes.SHIFT_TIME_PROPOSAL_UPDATED,
      MessageCustomTypes.SHIFT_TIME_PROPOSAL_REPLIED,
    ].includes(message.customType as MessageCustomTypes) &&
    "message" in message &&
    typeof message.message === "string"
  ) {
    return <CbhCustomChatMessage message={message.message} />;
  }

  if (
    message.customType === MessageCustomTypes.FCM_APPROVAL ||
    message.customType === MessageCustomTypes.FCM_REJECT
  ) {
    return <SentHomeResponseMessage message={message} />;
  }

  if (
    message.customType === MessageCustomTypes.SHIFT_REVIEW ||
    message.customType === MessageCustomTypes.INPUT_ETA ||
    message.customType === MessageCustomTypes.INPUT_ETA_SUBMITTED
  ) {
    return <span />;
  }

  if (
    isDefined(currentChannel) &&
    isDefined(channelUrl) &&
    message.customType === MessageCustomTypes.PLACEMENT_APPLICATION
  ) {
    return (
      <PlacementApplicationMessage
        message={message}
        currentChannel={currentChannel}
        channelUrl={channelUrl}
      />
    );
  }

  if (
    isDefined(currentChannel) &&
    isDefined(channelUrl) &&
    message.customType === MessageCustomTypes.PLACEMENT_APPLICATION_AUTO_INTERVIEW_INVITE
  ) {
    return (
      <PlacementApplicationAutoInterviewInvite
        message={message}
        currentChannel={currentChannel}
        channelUrl={channelUrl}
      />
    );
  }

  if (
    isDefined(currentChannel) &&
    message.customType === MessageCustomTypes.PLACEMENT_APPLICATION_INVITE
  ) {
    return <PlacementApplicationInvite message={message as EveryMessage} />;
  }

  // We have to return undefined to render the sendbird message component and using any other value will make the message not visible on the UI.
  return undefined as unknown as React.ReactElement;
}
