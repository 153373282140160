import { Reducer, useCallback, useEffect, useReducer } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch } from "react-redux";
import { SHIFT_CANCELLATION_REASON_TYPES } from "src/constants/shifts";
import { deleteShift, unassignShift } from "src/api/shifts";
import { getErrorMessage } from "src/utils/errors";
import { fetchWorkerReviews } from "src/api/workerReview";

import {
  fetchExclusionDetails,
  fetchAgentDetails,
  fetchAssignedShifts,
  fetchAssignedShiftsCount,
} from "./api";
import { initialState, reducer } from "./reducer";

import {
  HTTPError,
  ReducerAction,
  ReducerActions,
  UseWorkplaceWorkerProps,
  UseWorkplaceWorkerReturnType,
  WorkplaceWorkerState,
} from "./workplaceWorker.types";

import { WhoCalledRatingPerformanceModal } from "src/components/WorkerRatingPerformanceModal/workerReview.types";
import { SHOW_WORKER_REVIEW_MODAL } from "src/modules/shifts";
import { createEvent } from "src/api/events";
import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { getActiveTabName, logOpenWorkerReview, logViewDocument } from "../logs";
import { WhoCalledChatModal, logChatOpen } from "src/containers/chat/utils/logEvents";
import { showErrorToast, showInfoToast } from "@src/appV2/lib/Notifications";

export function useWorkplaceWorker(props: UseWorkplaceWorkerProps): UseWorkplaceWorkerReturnType {
  const { workplaceId, workerId, workplaceUserId, admin, adminUserId } = props;
  const [state, dispatch] = useReducer<Reducer<WorkplaceWorkerState, ReducerActions>>(
    reducer,
    initialState
  );

  const globalDispatch = useDispatch();

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: state.isLoading.shiftDetails[state.activeTab],
    hasNextPage: !!state.shiftDetails[state.activeTab].pageToken,
    onLoadMore: useCallback(() => dispatch({ type: ReducerAction.LOAD_MORE_ASSIGNED_SHIFTS }), []),
    delayInMs: 10,
  });

  const openChatModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (workplaceUserId) {
      logChatOpen({
        workerId,
        workplaceId,
        workplaceUserId,
        location: WhoCalledChatModal.WORKER_DETAILS,
        activeTab: getActiveTabName(state.activeTab),
      });
    }
    dispatch({
      type: ReducerAction.SHOW_CHAT_MODAL,
      payload: `${workplaceId}_${workerId}`,
    });
  };

  const openWorkerReviewModal = useCallback(() => {
    if (workplaceUserId) {
      logOpenWorkerReview({
        workerId,
        workplaceId,
        workplaceUserId,
        location: WhoCalledRatingPerformanceModal.WORKER_DETAILS,
        activeTab: getActiveTabName(state.activeTab),
        shiftId: state.lastWorkedShift?._id,
      });
    }
    globalDispatch({
      type: SHOW_WORKER_REVIEW_MODAL,
      workerId,
      workplaceId,
      workplaceUserId,
      workerName: state.workerDetails?.name,
      whoCalledMe: WhoCalledRatingPerformanceModal.WORKER_DETAILS,
      trackData: { activeTab: getActiveTabName(state.activeTab) },
      successCallback: (workerReview) =>
        dispatch({
          type: ReducerAction.SET_WORKER_REVIEWS,
          payload: [workerReview],
        }),
    });
  }, [
    globalDispatch,
    workerId,
    workplaceId,
    workplaceUserId,
    state.workerDetails,
    state.lastWorkedShift,
  ]);

  const openDnrModal = useCallback(() => {
    dispatch({ type: ReducerAction.SHOW_BLOCK_OR_UNBLOCK_MODAL });
  }, [dispatch]);

  const onConfirmCancelShift = async (reasonType, reasonDescription) => {
    const { selectedCancellingShift } = state;
    if (!selectedCancellingShift) {
      return;
    }
    try {
      // When reason selected is `NO_CALL_NO_SHOW` un-assigning the agent from the shift.
      if (reasonType === SHIFT_CANCELLATION_REASON_TYPES.NO_CALL_NO_SHOW) {
        await unassignShift({
          shiftId: selectedCancellingShift._id,
          agentId: selectedCancellingShift.agentId,
          isAgentCancel: false,
          isNoCallNoShow: true,
          userId: workplaceId,
          reasonType,
          reasonDescription,
        });
        showInfoToast("Shift unassigned");
      } else {
        await createEvent(selectedCancellingShift._id, "FACILITY_CANCEL");
        await deleteShift({
          shiftId: selectedCancellingShift._id,
          userId: workplaceId,
          isFacilityCancel: true,
          reasonType,
          reasonDescription,
        });
        showInfoToast("Shift deleted");
      }
      dispatch({
        type: ReducerAction.REMOVE_SHIFT_FROM_VIEW,
        payload: { shiftId: selectedCancellingShift._id },
      });
      logEvent(HCF_USER_EVENTS.CANCEL_SHIFT, {
        shiftId: selectedCancellingShift._id,
        workerId,
        workplaceId,
        workplaceUserId,
        // we should standardize the location
        location: WhoCalledRatingPerformanceModal.WORKER_DETAILS,
        activeTab: state.activeTab,
      });
    } catch (err) {
      showErrorToast(`Shift couldn't be deleted`);
      console.error("Failed to cancel: " + getErrorMessage(err));
    } finally {
      dispatch({ type: ReducerAction.HIDE_CANCEL_SHIFT_MODAL });
    }
  };

  const onViewDocument = () => {
    if (workplaceUserId) {
      logViewDocument({
        workerId,
        workplaceId,
        workplaceUserId,
        location: WhoCalledRatingPerformanceModal.WORKER_DETAILS,
        activeTab: getActiveTabName(state.activeTab),
      });
    }
  };

  useEffect(() => {
    if (!workerId || !workplaceId || !state.isLoading.shiftsCount) {
      return;
    }
    (async () => {
      try {
        const payload = await fetchAssignedShiftsCount({
          workerId,
          workplaceId,
        });
        dispatch({ type: ReducerAction.SET_ASSIGNED_SHIFTS_COUNT, payload });
      } catch (error) {
        showErrorToast("Cannot load count of shifts worked by the worker, please try again later.");
      }
    })();
  }, [workerId, workplaceId, dispatch, state.isLoading.shiftsCount]);

  useEffect(() => {
    if (!workerId || !workplaceId || !state.isLoading.shiftDetails[state.activeTab]) {
      return;
    }
    (async () => {
      try {
        const { activeTab } = state;
        const { pageToken } = state.shiftDetails[state.activeTab];
        const data = await fetchAssignedShifts({
          workerId,
          workplaceId,
          category: activeTab,
          ...(pageToken ? { pageToken } : {}),
        });
        dispatch({
          type: ReducerAction.SET_ASSIGNED_SHIFTS,
          payload: {
            type: activeTab,
            shifts: data.shifts,
            pageToken: data.pageToken,
          },
        });
      } catch (error) {
        dispatch({ type: ReducerAction.SET_ERROR_ON_ASSIGNED_SHIFTS });
      }
    })();
    // NOTE:: Do not add anymore dependencies here.
  }, [
    workerId,
    workplaceId,
    dispatch,
    state.isLoading.shiftDetails[state.activeTab],
    state.activeTab,
  ]);

  useEffect(() => {
    if (!workerId || !workplaceId || !state.isLoading.workerDetails) {
      return;
    }
    (async () => {
      try {
        const payload = await fetchAgentDetails({ workerId, workplaceId });
        dispatch({ type: ReducerAction.SET_WORKER_DETAILS, payload });
      } catch (err) {
        const error = err as HTTPError;
        dispatch({
          type: ReducerAction.SET_ERROR_ON_WORKER_DETAILS,
          payload: typeof error?.status === "number" ? error.status : true,
        });
      }
    })();
  }, [workerId, dispatch, state.isLoading.workerDetails, workplaceId]);

  useEffect(() => {
    if (!workerId || !workplaceId || !state.isLoading.exclusion) {
      return;
    }
    (async () => {
      try {
        const payload = await fetchExclusionDetails({ workerId, workplaceId });
        dispatch({
          type: ReducerAction.SET_EXCLUSION_DETAILS,
          payload: payload[0],
        });
      } catch (error) {
        dispatch({ type: ReducerAction.SET_ERROR_ON_EXCLUSION });
        showErrorToast("Error loading DNR for the worker.");
      }
    })();
  }, [workerId, workplaceId, dispatch, state.isLoading.exclusion]);

  useEffect(() => {
    if (!workerId || !workplaceId || !state.isLoading.workerReviews) {
      return;
    }
    (async () => {
      try {
        const payload = await fetchWorkerReviews({
          workerId,
          workplaceId,
        });
        dispatch({ type: ReducerAction.SET_WORKER_REVIEWS, payload });
      } catch (error) {
        dispatch({ type: ReducerAction.SET_ERROR_ON_WORKER_REVIEWS });
        showErrorToast("Error loading review details for the worker.");
      }
    })();
  }, [workerId, workplaceId, state.isLoading.workerReviews]);

  return {
    ...state,
    dispatch,
    infiniteScrollRef,
    openWorkerReviewModal,
    openDnrModal,
    openChatModal,
    onConfirmCancelShift,
    onViewDocument,
  };
}
