import React, { useState, useImperativeHandle, forwardRef } from "react";
import { SelectChangeEvent, Stack } from "@mui/material";
import { useDnrConfig } from "./helpers";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { createFeedbackRequiredMessage } from "./dnrFormConstants";
import { validateDnrForm } from "./dnrFormValidation";
import { DnrReasonSelect } from "./DnrReasonSelect";
import { DnrSeveritySelect } from "./DnrSeveritySelect";
import { DnrFeedbackText } from "./DnrFeedbackText";
import { DnrFeedbackField } from "./DnrFeedbackField";

interface DnrSubmissionFormComponentProps {
  value?: string;
  onChange?: (value: string) => void;
  onReasonSelect?: (dnrCharacterFloor: number) => void;
  placeholder?: string;
  fullWidth?: boolean;
  additionalFeedback?: string;
  onAdditionalFeedbackChange?: (feedback: string) => void;
  minFeedbackLength?: number;
  error?: boolean;
  helperText?: string;
  severityResponse?: string;
  onSeverityChange?: (value: string) => void;
  headerText?: string;
  showHeader?: boolean;
}

export interface DnrSubmissionFormComponentRef {
  validate: () => boolean;
}

/**
 * A reusable Material-UI Select component for selecting DNR reasons with severity
 * Uses the DNR_CONFIG feature flag with fallback to DEFAULT_DNR_REASONS
 */
export const DnrSubmissionFormComponent = forwardRef<
  DnrSubmissionFormComponentRef,
  DnrSubmissionFormComponentProps
>(
  (
    {
      value,
      onChange,
      onReasonSelect,
      placeholder = "Select a reason",
      fullWidth = true,
      additionalFeedback = "",
      onAdditionalFeedbackChange,
      minFeedbackLength = 0,
      error = false,
      helperText = "Please select a reason to DNR this professional",
      severityResponse = "",
      onSeverityChange,
      headerText = "Please select a reason for DNR:",
      showHeader = true,
    },
    ref
  ) => {
    const dnrConfig = useDnrConfig();
    const [localMinFeedbackLength, setLocalMinFeedbackLength] = useState(minFeedbackLength);
    const shouldShowUpdatedDNRCopy = useCbhFlag(CbhFeatureFlag.SHOW_UPDATED_DNR_COPY, {
      defaultValue: false,
    });
    const [reasonError, setReasonError] = useState(error);
    const [severityError, setSeverityError] = useState(false);
    const [feedbackError, setFeedbackError] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const feedbackRequiredMessage = createFeedbackRequiredMessage(minFeedbackLength);

    // Expose validate method to parent components
    useImperativeHandle(ref, () => ({
      validate: () =>
        validateDnrForm({
          value,
          severityResponse,
          hasSeverity: isDefined(dnrConfig.severity),
          hasSeverityChange: !!onSeverityChange,
          additionalFeedback,
          hasAdditionalFeedbackChange: !!onAdditionalFeedbackChange,
          localMinFeedbackLength,
          setFormSubmitted,
          setReasonError,
          setSeverityError,
          setFeedbackError,
        }),
    }));

    const handleChange = (event: SelectChangeEvent<string>) => {
      const selectedValue = event.target.value;

      // Clear reason error when a value is selected
      setReasonError(false);

      // Also clear feedback error when a new reason is selected
      setFeedbackError(false);

      // Reset formSubmitted state to prevent feedback error from showing
      setFormSubmitted(false);

      if (onChange) {
        onChange(selectedValue);
      }

      if (onReasonSelect) {
        const selectedReason = dnrConfig.reasons.find((reason) => reason.value === selectedValue);
        if (selectedReason) {
          onReasonSelect(selectedReason.dnrCharacterFloor);
          setLocalMinFeedbackLength(selectedReason.dnrCharacterFloor);
        }
      }
    };

    const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onAdditionalFeedbackChange?.(event.target.value);
      if (event.target.value.length >= localMinFeedbackLength) {
        setFeedbackError(false);
        // Reset formSubmitted state when feedback is valid
        setFormSubmitted(false);
      }
    };

    const handleSeverityChange = (event: SelectChangeEvent<string>) => {
      onSeverityChange?.(event.target.value);
      setSeverityError(false);
    };

    return (
      <Stack spacing={2}>
        {showHeader && (
          <Text variant="subtitle2" sx={{ fontSize: "0.875rem", marginBottom: "4px" }}>
            {headerText}
          </Text>
        )}

        <DnrReasonSelect
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          fullWidth={fullWidth}
          reasonError={reasonError}
          helperText={helperText}
          reasons={dnrConfig.reasons}
        />

        {isDefined(dnrConfig.severity) && onSeverityChange && (
          <DnrSeveritySelect
            severityResponse={severityResponse}
            onSeverityChange={handleSeverityChange}
            severityError={severityError}
            severityConfig={dnrConfig.severity}
          />
        )}

        {onAdditionalFeedbackChange && (
          <>
            <DnrFeedbackText shouldShowUpdatedDNRCopy={shouldShowUpdatedDNRCopy} />
            <DnrFeedbackField
              additionalFeedback={additionalFeedback}
              onFeedbackChange={handleFeedbackChange}
              formSubmitted={formSubmitted}
              feedbackError={feedbackError}
              localMinFeedbackLength={localMinFeedbackLength}
              feedbackRequiredMessage={feedbackRequiredMessage}
            />
          </>
        )}
      </Stack>
    );
  }
);

// Add display name to fix linter error
DnrSubmissionFormComponent.displayName = "DnrReasonFeedbackWithSeverity";
