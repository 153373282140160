import { z } from "zod";
import { Address } from "../lib/GoogleMaps";
import { isNullOrUndefined } from "@clipboard-health/util-ts";

export enum PlacementCandidateStatus {
  DISABLED = "disabled",
  ONBOARDING = "onboarding",
  PENDING = "pending",
  ACTIVATED = "activated",
}

export enum JobType {
  FULL_TIME = "full_time",
  PART_TIME = "part_time",
  PER_DIEM = "per_diem",
  WEEKEND_WARRIOR = "weekend_warrior",
}

export enum ShiftNameType {
  AM = "am",
  PM = "pm",
  NOC = "noc",
}

export interface CredentialType {
  id: string;
  type: "license" | "document";
  name: string;
  isRequired?: boolean;
}

export const placementGeoLocation = z.object({
  coordinates: z.tuple([z.number(), z.number()]),
  type: z.literal("Point"),
});

export type PlacementGeoLocation = z.infer<typeof placementGeoLocation>;

export interface PreferredCandidateArea {
  location: PlacementGeoLocation;
  address: Address;
  distance: number;
}

const placementCandidateUploadedResumeStatusSchema = z.enum([
  "no_file",
  "pending",
  "valid",
  "invalid",
]);

export const placementCandidateUploadedResumeSchema = z.object({
  type: z.literal("uploaded-resume"),
  id: z.string(),
  attributes: z.object({
    status: placementCandidateUploadedResumeStatusSchema,
    createdAt: z.coerce.date().optional(),
    updatedAt: z.coerce.date().optional(),
    resumeUrl: z.string().optional(),
    passportUrl: z.string().optional(),
    parsingDecision: z.string().optional(),
    parsingDecisionReason: z.array(z.string()).optional(),
  }),
});

export type PlacementCandidateUploadedResume = z.infer<
  typeof placementCandidateUploadedResumeSchema
>;

export type PlacementCandidate = {
  id: string;
  accessId: string;
  status: PlacementCandidateStatus;
  introductionUrl?: string;
  distance?: number;
  isWorkplaceFavorite?: boolean;
  jobTypesInterested?: JobType[];
  shiftTypesInterested?: ShiftNameType[];
  preferredAreas?: PreferredCandidateArea[];
  hasAppliedToWorkplace?: boolean;
  worker: {
    id: string;
    name: string;
    qualification: string;
    completedShiftsCount?: number;
    documents?: { id: string; name: string }[];
    licenses?: {
      id: string;
      multiState: boolean;
      state: string;
      qualification: string;
    }[];
    email?: string;
    phone?: string;
    profileImageUrl?: string;
  };
  pendingResume?: PlacementCandidateUploadedResume["attributes"];
  resume?: PlacementCandidateUploadedResume["attributes"];
};

export const placementCandidateFormSchema = z.object({
  status: z.nativeEnum(PlacementCandidateStatus, {
    required_error: "Status is required",
  }),
  introductionUrl: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (value === "" || isNullOrUndefined(value)) {
          return true;
        }
        try {
          const url = new URL(value);
          const allowedHosts = ["videoask.com", "www.videoask.com"];
          return allowedHosts.includes(url.host);
        } catch (e) {
          return false;
        }
      },
      {
        message: "Please enter a valid VideoAsk link",
      }
    ),
  jobTypesInterested: z.array(z.nativeEnum(JobType)),
  shiftTypesInterested: z.array(z.nativeEnum(ShiftNameType)),
  preferredArea: z
    .object({
      coordinates: z.object({
        latitude: z.number(),
        longitude: z.number(),
      }),
      address: z.object({
        city: z.string().optional(),
        country: z.string().optional(),
        countryCode: z.string().optional(),
        formatted: z.string().optional(),
        line1: z.string().optional(),
        postalCode: z.string().optional(),
        region: z.string().optional(),
        subpremise: z.string().optional(),
        state: z.string().optional(),
        stateCode: z.string().optional(),
        streetName: z.string().optional(),
        streetNumber: z.string().optional(),
        metropolitanStatisticalArea: z.string().optional(),
      }),
    })
    .optional(),
  distance: z.number().min(1).max(150),
  isWorkplaceFavorite: z.boolean().optional(),
});
