/* eslint-disable max-lines */
import { z } from "zod";

export const responseErrorSchema = z.object({
  code: z.string(),
  id: z.string(),
  status: z.string(),
  title: z.string(),
  detail: z.string(),
});
export type ResponseError = z.infer<typeof responseErrorSchema>;

export const responseWithErrorSchema = z.object({
  errors: z.array(responseErrorSchema),
});
export type ResponseWithError = z.infer<typeof responseWithErrorSchema>;

export const patientSchema = z.object({
  id: z.string(),
  attributes: z.object({
    createdAt: z.string(),
    workplaceId: z.string(),
    externalPatientIdentifier: z.string(),
    formattedAddress: z.string(),
    latitude: z.number(),
    longitude: z.number(),
    oasis: z.boolean(),
  }),
  type: z.literal("patient"),
});
export type Patient = z.infer<typeof patientSchema>;

export const patientResponseSchema = z.object({
  data: patientSchema,
  errors: z.array(responseErrorSchema).optional(),
});
export type PatientResponse = z.infer<typeof patientResponseSchema>;

export const createPatientRequestSchema = z.object({
  data: z.object({
    attributes: patientSchema.shape.attributes.omit({ createdAt: true }),
    type: patientSchema.shape.type,
  }),
});
export type CreatePatientRequest = z.infer<typeof createPatientRequestSchema>;

export const updatePatientRequestSchema = z.object({
  data: z.object({
    attributes: patientSchema.shape.attributes
      .omit({ createdAt: true, workplaceId: true })
      .deepPartial(),
    type: z.literal("patient"),
  }),
});
export type UpdatePatientRequest = z.infer<typeof updatePatientRequestSchema>;

export enum VisitType {
  ADMISSION = "ADMISSION",
  RESUMPTION_OF_CARE = "RESUMPTION_OF_CARE",
  REGULAR = "REGULAR",
  EVALUATION = "EVALUATION",
  RECERTIFICATION = "RECERTIFICATION",
  DISCHARGE = "DISCHARGE",
  SUPERVISORY = "SUPERVISORY",
}

export enum VisitStatus {
  OPEN = "OPEN",
  CANCELED = "CANCELED",
  FILLED = "FILLED",
  CLOSED = "CLOSED",
  LOGGED = "LOGGED",
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING",
}

export const visitWorkerSchema = z.object({
  userId: z.string(),
  name: z.string(),
  email: z.string(),
});
export type VisitWorker = z.infer<typeof visitWorkerSchema>;

export enum VisitOccurrenceStatus {
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
}

export enum PricingType {
  PER_HOUR = "PER_HOUR",
  PER_VISIT = "PER_VISIT",
}
export const pricingTypeSchema = z.nativeEnum(PricingType);

export const visitOccurrenceSchema = z.object({
  attributes: z.object({
    status: z.nativeEnum(VisitOccurrenceStatus),
    completedAt: z.string(),
    externalPatientIdentifier: z.string(),
    visitId: z.string(),
    rejectionReason: z.string().optional(),
    actionedBy: z.string().optional(),
    actionedAt: z.string().optional(),
    pricingType: pricingTypeSchema,
    // required for PER_HOUR visit occurrences
    estimatedDuration: z.number().optional(),
    payRate: z
      .object({
        amountInMinorUnits: z.number(),
        currencyCode: z.literal("USD"),
      })
      .optional(),
    chargeRate: z
      .object({
        amountInMinorUnits: z.number(),
        currencyCode: z.literal("USD"),
      })
      .optional(),
    paid: z.boolean(),
    instantPay: z.boolean().optional(),
    workerId: z.string(),
  }),
  id: z.string(),
  type: z.literal("visitOccurrence"),
});
export type VisitOccurrence = z.infer<typeof visitOccurrenceSchema>;

export enum WorkerRequirements {
  RN = "RN",
  LVN = "LVN",
  CNA = "CNA",
  CAREGIVER = "Caregiver",
  CHHA = "CHHA",
  PT = "Physical Therapist",
  PTA = "Physical Therapist Assistant",
}
export type EvaluationVisitWorkerRequirements = WorkerRequirements.PT;
export const EVALUATION_WORKER_REQUIREMENTS: EvaluationVisitWorkerRequirements[] = [
  WorkerRequirements.PT,
];

export type AdmissionVisitWorkerRequirements = WorkerRequirements.PT | WorkerRequirements.RN;
export const ADMISSION_WORKER_REQUIREMENTS: AdmissionVisitWorkerRequirements[] = [
  WorkerRequirements.RN,
  WorkerRequirements.PT,
];

export type DischargeVisitWorkerRequirements = WorkerRequirements.PT | WorkerRequirements.RN;
export const DISCHARGE_WORKER_REQUIREMENTS: DischargeVisitWorkerRequirements[] = [
  WorkerRequirements.RN,
  WorkerRequirements.PT,
];

export type PtWorkerRequirements = WorkerRequirements.PT | WorkerRequirements.PTA;
export type NonPtWorkerRequirements = Exclude<WorkerRequirements, PtWorkerRequirements>;

export const workerRequirementsSchema = z.nativeEnum(WorkerRequirements);

export const NON_PT_WORKER_REQUIREMENTS: NonPtWorkerRequirements[] = [
  WorkerRequirements.RN,
  WorkerRequirements.LVN,
  WorkerRequirements.CNA,
  WorkerRequirements.CAREGIVER,
  WorkerRequirements.CHHA,
];
export const PT_WORKER_REQUIREMENTS: PtWorkerRequirements[] = [
  WorkerRequirements.PT,
  WorkerRequirements.PTA,
];
export const WORKER_REQUIREMENTS: WorkerRequirements[] = (
  NON_PT_WORKER_REQUIREMENTS as Array<WorkerRequirements>
).concat(PT_WORKER_REQUIREMENTS as Array<WorkerRequirements>);

export const visitSchema = z.object({
  id: z.string(),
  attributes: z.object({
    createdAt: z.string(),
    caseId: z.string(),
    status: z.nativeEnum(VisitStatus),
    type: z.nativeEnum(VisitType),
    payRate: z
      .object({
        amountInMinorUnits: z.number(),
        currencyCode: z.literal("USD"),
      })
      .optional(),
    chargeRate: z
      .object({
        amountInMinorUnits: z.number(),
        currencyCode: z.literal("USD"),
      })
      .optional(),
    // FIXME remove interestedWorkersIds and invitedWorkersIds from schema and remove optional() from the non-ids version once BE fixes it
    interestedWorkersIds: z.array(z.string()),
    interestedWorkers: z.array(visitWorkerSchema).optional(),
    invitedWorkersNames: z.array(z.string()),
    invitedWorkersIds: z.array(z.string()),
    invitedWorkers: z.array(visitWorkerSchema).optional(),
    bookedWorkerId: z.string().nullable().optional(),
    visitOccurrences: z.array(visitOccurrenceSchema).optional(),
    details: z.string().optional(),
    // only for ADMISSION and RESUMPTION_OF_CARE visit types
    deadline: z.string().optional(),
    // only for REGULAR visit type
    workerReq: workerRequirementsSchema.optional(),
    // only for REGULAR visit type
    visitsPerWeek: z.number().optional(),
    // only for REGULAR visit type
    durationInWeeks: z.number().optional(),
    // only for REGULAR visit type
    pricingType: pricingTypeSchema,
    // only for REGULAR visit type
    estimatedStartTime: z.string().nullable().optional(),
    // only for REGULAR visit type
    estimatedDuration: z.number().optional(),
  }),
  type: z.literal("visit"),
});
// Have to manually extend the type because there is no zod schema for Agent yet
export type Visit = z.infer<typeof visitSchema>;

export const visitResponseSchema = z.object({
  data: visitSchema,
  errors: z.array(responseErrorSchema).optional(),
});
export type VisitResponse = z.infer<typeof visitResponseSchema>;

export const createVisitRequestSchema = z.object({
  data: z.object({
    attributes: visitSchema.shape.attributes
      .omit({
        createdAt: true,
        invitedWorkers: true,
        interestedWorkers: true,
      })
      .extend({
        automaticOutreachDisabled: z.boolean().optional(),
      }),
    type: visitSchema.shape.type,
  }),
});
export type CreateVisitRequest = z.infer<typeof createVisitRequestSchema>;

export const updateVisitRequestSchema = z.object({
  data: z.object({
    attributes: createVisitRequestSchema.shape.data.shape.attributes.partial(),
    type: createVisitRequestSchema.shape.data.shape.type,
  }),
});
export type UpdateVisitRequest = z.infer<typeof updateVisitRequestSchema>;

export enum CaseStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}
export const caseStatusSchema = z.nativeEnum(CaseStatus);

export const caseFilterSchema = z.object({
  status: caseStatusSchema,
  externalPatientIdentifier: z.string().optional(),
  formattedAddress: z.string().optional(),
});
export type CaseFilter = z.infer<typeof caseFilterSchema>;

export enum CaseSort {
  PATIENT_ID_ASC = "externalPatientIdentifier",
  PATIENT_ID_DESC = "-externalPatientIdentifier",

  CREATED_AT_ASC = "createdAt",
  CREATED_AT_DESC = "-createdAt",
}
export const caseSortSchema = z.nativeEnum(CaseSort);

export const casesParamsSchema = z.object({
  filter: caseFilterSchema.optional(),
  sort: caseSortSchema.optional(),
});
export type CasesParams = z.infer<typeof casesParamsSchema>;

export const caseSchema = z.object({
  attributes: z.object({
    createdAt: z.string(),
    workplaceId: z.string(),
    patient: patientSchema,
    specialties: z.array(z.string()),
    status: z.nativeEnum(CaseStatus),
    description: z.string(),
    visits: z.array(visitSchema),
  }),
  id: z.string(),
  type: z.literal("case"),
});
export type Case = z.infer<typeof caseSchema>;

export const caseResponseSchema = z.object({
  data: caseSchema,
  errors: z.array(responseErrorSchema).optional(),
});
export type CaseResponse = z.infer<typeof caseResponseSchema>;

export const casesResponseSchema = z.object({
  data: z.array(caseSchema),
  errors: z.array(responseErrorSchema).optional(),
});
export type CasesResponse = z.infer<typeof casesResponseSchema>;

export const createCaseRequestSchema = z.object({
  data: z.object({
    attributes: caseSchema.shape.attributes
      .omit({
        createdAt: true,
        patient: true,
        status: true,
        visits: true,
      })
      .extend({
        patientId: z.string(),
      }),
    type: caseSchema.shape.type,
  }),
});
export type CreateCaseRequest = z.infer<typeof createCaseRequestSchema>;

export const caseVisitsResponseSchema = z.object({
  data: z.array(visitSchema),
  errors: z.array(responseErrorSchema).optional(),
});
export type CaseVisitsResponse = z.infer<typeof caseVisitsResponseSchema>;

export const bookVisitAgentRequestSchema = z.object({
  data: z.object({
    workerId: z.string(),
  }),
});
export type BookAgentVisitRequest = z.infer<typeof bookVisitAgentRequestSchema>;

export const bookVisitAgentResponseSchema = z.object({
  data: z.object({}),
  errors: z.array(responseErrorSchema),
});
export type BookAgentVisitResponse = z.infer<typeof bookVisitAgentResponseSchema>;

export const updateCaseRequestSchema = z.object({
  data: z.object({
    attributes: caseSchema.shape.attributes
      .omit({ createdAt: true, patient: true, workplaceId: true, visits: true })
      .deepPartial(),
    type: z.literal("case"),
  }),
});
export type UpdateCaseRequest = z.infer<typeof updateCaseRequestSchema>;

export const inviteWorkerRequestSchema = z.object({
  data: z.object({
    attributes: z.object({
      visitId: z.string(),
      workerId: z.string(),
    }),
    type: z.literal("invite"),
  }),
});
export type InviteWorkerRequest = z.infer<typeof inviteWorkerRequestSchema>;

export const inviteWorkerResponseSchema = z.object({
  data: z.object({
    attributes: z.object({
      visitId: z.string(),
      workerId: z.string(),
      sentAt: z.string(),
    }),
    id: z.string(),
    type: z.literal("invite"),
  }),
  relationships: z.object({
    visit: visitSchema,
  }),
});
export type InviteWorkerResponse = z.infer<typeof inviteWorkerResponseSchema>;

export const invitableWorkerToVisitSchema = z.object({
  approvedVisitOccurrencesCount: z.number(),
  isInvitedToVisit: z.boolean(),
  workerId: z.string(),
  workerName: z.string(),
  distanceFromPatientInMiles: z.number(),
});
export type InvitableWorkerToVisit = z.infer<typeof invitableWorkerToVisitSchema>;

export const invitableWorkersToVisitRequestSchema = z
  .object({
    offset: z.number().optional(),
    limit: z.number().optional(),
  })
  .optional();
export type InvitableWorkerToVisitRequest = z.infer<typeof invitableWorkersToVisitRequestSchema>;

export const invitableWorkersToVisitResponseSchema = z.object({
  data: z.object({
    workers: z.array(invitableWorkerToVisitSchema),
    nextOffset: z.number().nullable(),
  }),
});
export type InvitableWorkerToVisitResponse = z.infer<typeof invitableWorkersToVisitResponseSchema>;
