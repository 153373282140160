import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Stack,
  FormHelperText,
} from "@mui/material";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { selectStyles, menuItemStyles } from "./dnrFormStyles";

interface DnrSeveritySelectProps {
  severityResponse?: string;
  onSeverityChange: (event: SelectChangeEvent<string>) => void;
  severityError: boolean;
  severityConfig: {
    question: string;
    subtext?: string;
    response: string[];
  };
}

/**
 * Component for selecting DNR severity
 */
export const DnrSeveritySelect: React.FC<DnrSeveritySelectProps> = ({
  severityResponse,
  onSeverityChange,
  severityError,
  severityConfig,
}) => {
  return (
    <Stack spacing={1}>
      <Stack spacing={0}>
        <Text variant="subtitle2" sx={{ fontSize: "0.875rem" }}>
          {severityConfig.question}
        </Text>
        {isDefined(severityConfig.subtext) && (
          <Text
            gutterBottom
            variant="caption"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            {severityConfig.subtext}
          </Text>
        )}
      </Stack>
      <FormControl fullWidth error={severityError}>
        <Select
          value={severityResponse || ""}
          onChange={onSeverityChange}
          displayEmpty
          placeholder="Choose one"
          data-testid="severity-select"
          error={severityError}
          sx={selectStyles}
        >
          <MenuItem value="" disabled sx={menuItemStyles}>
            Choose one
          </MenuItem>
          {severityConfig.response.map((option) => (
            <MenuItem key={option} value={option} sx={menuItemStyles}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {severityError && <FormHelperText>This question is required</FormHelperText>}
      </FormControl>
    </Stack>
  );
};
