import { FirebaseAuth } from "@src/utils/firebase";
import { APP_V2_APP_EVENTS, logError } from "@src/appV2/lib/analytics";

const parseFirebaseAuthError = (error) => {
  switch (error.code) {
    case "auth/user-disabled":
      return {
        error:
          "Your account has been deactivated, if you believe this is in error please call or text us.",
      };

    case "auth/network-request-failed":
      return {
        error: "Network Connectivity error. Check your network connection and try again later.",
      };

    case "auth/quota-exceeded":
      return {
        error: "Verification code error. Try again after after an hour.",
      };

    case "auth/expired-action-code":
      return {
        error: "Verification code expired. Try requesting again",
      };

    case "auth/invalid-email":
      return {
        error: "Your Email Id is not correct",
        code: error.code,
      };

    case "auth/invalid-verification-code":
      return {
        error: "Your link is expired or invalid!",
      };

    case "auth/invalid-action-code":
      return {
        error: "Your link is expired or invalid !",
      };

    default:
      return {
        error: error.message,
      };
  }
};

const signInWithEmailLink = async (email) => {
  const emailToLogin = email || localStorage.getItem("emailToVerify");
  let url = window.location.href;
  const originalAuthURL = sessionStorage.getItem("originalAuthURL");
  if (originalAuthURL) {
    url = originalAuthURL;
    sessionStorage.removeItem("originalAuthURL");
  }

  try {
    await FirebaseAuth.signInWithEmailLink(emailToLogin, url);
    localStorage.removeItem("emailToVerify");
    return { success: true };
  } catch (error: unknown) {
    const firebaseErrorDetails = parseFirebaseAuthError(error);

    logError(APP_V2_APP_EVENTS.SIGN_IN_WITH_EMAIL_LINK_FIREBASE_FAILURE, {
      error,
      metadata: {
        email,
        localStorageEmail: localStorage.getItem("emailToVerify"),
        errorMessage: firebaseErrorDetails.error,
        errorCode: firebaseErrorDetails.code,
      },
    });

    return firebaseErrorDetails;
  }
};

const signInWithCustomToken = async (token) => {
  try {
    await FirebaseAuth.signInWithCustomToken(token);
    return { success: true };
  } catch (error: unknown) {
    const firebaseErrorDetails = parseFirebaseAuthError(error);

    logError(APP_V2_APP_EVENTS.SIGN_IN_WITH_CUSTOM_TOKEN_FIREBASE_FAILURE, {
      error,
      metadata: {
        errorMessage: firebaseErrorDetails.error,
        errorCode: firebaseErrorDetails.code,
      },
    });

    return firebaseErrorDetails;
  }
};

export async function signInWithEmailAndPassword(email, password) {
  try {
    await FirebaseAuth.signInWithEmailAndPassword(email, password);
    return { success: true };
  } catch (error: unknown) {
    const firebaseErrorDetails = parseFirebaseAuthError(error);

    logError(APP_V2_APP_EVENTS.SIGN_IN_WITH_EMAIL_PASSWORD_FIREBASE_FAILURE, {
      error,
      metadata: {
        email,
        errorMessage: firebaseErrorDetails.error,
        errorCode: firebaseErrorDetails.code,
      },
    });

    return firebaseErrorDetails;
  }
}

export { signInWithEmailLink, signInWithCustomToken };
