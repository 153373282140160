import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { useShiftInviteContext } from "./Context/shiftInviteContext";
import { getShiftTagColor } from "@src/components/shift";

type GroupInviteDayPickerProps = PickersDayProps<Date> & {
  selectedDate?: Date;
};

export function GroupShiftInviteDayPicker(props: GroupInviteDayPickerProps) {
  const { selectedDate, day, ...otherProps } = props;
  const { multipleShiftInfo } = useShiftInviteContext();

  const highlightedDayShiftTypesMap = [...multipleShiftInfo.values()].reduce((map, shiftInfo) => {
    const selectedDayISO = shiftInfo.selectedDate?.toISOString() ?? "";
    map.set(selectedDayISO, [...(map.get(selectedDayISO) ?? []), shiftInfo.name]);
    return map;
  }, new Map<string, string[]>());
  const selected = [...highlightedDayShiftTypesMap.keys()].some((selectedDay) => {
    return selectedDay === day.toISOString();
  });
  const selectedShiftTypes = [...(highlightedDayShiftTypesMap.get(day.toISOString()) ?? [])];
  const isCurrentSelectedDay = day.toISOString() === selectedDate?.toISOString();

  return (
    <PickersDay
      {...otherProps}
      day={day}
      selected={selected}
      sx={{
        "&:focus": {
          backgroundColor: "inherit",
        },
        // Hover needs to be specified otherwise it will be overridden by focus and not show for focused day
        "&:hover": {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
        background: () => {
          const shiftTypeColors = ["am", "pm", "noc"]
            .filter((type) => selectedShiftTypes.includes(type) && selected)
            .map((type) => getShiftTagColor(type));
          switch (shiftTypeColors.length) {
            case 0:
              return "";
            case 1:
              return `${shiftTypeColors[0]} !important`;
            case 2:
              return `linear-gradient(to right, ${shiftTypeColors[0]} 50%, ${shiftTypeColors[1]} 50%)`;
            case 3:
              return `conic-gradient(${shiftTypeColors[0]} 33%, ${shiftTypeColors[1]} 33%, ${shiftTypeColors[1]} 67%, ${shiftTypeColors[2]} 67%)`;
            default:
              return "";
          }
        },
        border: isCurrentSelectedDay ? "2px solid #000" : "",
      }}
    />
  );
}
