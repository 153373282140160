import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";

const apiMainUrl = environmentConfig.REACT_APP_API_MAIN_URL;

export enum WorkerStatusLabel {
  Favorite = "Favorite",
  Restricted = "Restricted",
  Blocked = "Blocked",
}

const nearbyWorkerSearchResultSchema = z.object({
  workerId: z.string(),
  phoneNumber: z.string().optional(),
  fullName: z.string(),
  statusLabel: z.nativeEnum(WorkerStatusLabel).optional(),
});

const getNearbyWorkersResponseSchema = z.object({
  data: z.array(
    z.object({
      attributes: nearbyWorkerSearchResultSchema,
    })
  ),
});

export type NearbyWorkerSearchResult = z.infer<typeof nearbyWorkerSearchResultSchema>;

export interface GetNearbyWorkersParams {
  workplaceId: string;
  searchKey: string;
  workerType: string;
}
export function useGetNearbyWorkers(getNearbyWorkersParams: GetNearbyWorkersParams) {
  const { workplaceId, searchKey, workerType } = getNearbyWorkersParams;
  const getNearbyWorkerUrl = `${apiMainUrl}/api/workplaces/${workplaceId}/nearby-workers`;
  return useQuery({
    queryKey: [getNearbyWorkerUrl, searchKey, workerType],
    queryFn: async () => {
      const {
        data: { data: workers },
      } = await get({
        url: getNearbyWorkerUrl,
        responseSchema: getNearbyWorkersResponseSchema,
        queryParams: {
          filter: { name: searchKey, qualification: workerType, excludeDNR: false },
        },
      });
      return workers.map((record) => ({ ...record.attributes }));
    },
    enabled: Boolean(searchKey),
  });
}
