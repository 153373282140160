import { useDispatch, useSelector } from "react-redux";
import { SessionType } from "src/modules/interface";
import { isSignedFacilityProfile } from "src/utils/facility";
import {
  OpenWorkplaceContractModal,
  openWorkplaceContractModal,
} from "src/modules/workplaceContract";

export function useRestrictedSignedProfile() {
  const { profile, user, admin } = useSelector((state: SessionType) => state.session);
  const dispatch = useDispatch();

  const isSignedProfile = !!(
    admin ||
    (isSignedFacilityProfile(profile) && !user?.isSuspicious && user?.isEmailVerified)
  );

  const openContractModal = (data: OpenWorkplaceContractModal["data"]) => {
    if (!isSignedProfile) {
      dispatch(openWorkplaceContractModal(data));
    }
  };

  function wrapRestrictedAction<T>(action: T, data: OpenWorkplaceContractModal["data"]) {
    return isSignedProfile ? action : () => openContractModal(data);
  }

  return {
    isSignedProfile,
    openContractModal,
    wrapRestrictedAction,
  };
}
