import {
  ALERT_TYPE,
  CHARGE_RATE_VISIBLE_EMAIL,
  FACILITY_ROLES,
  getDefaultWorkplaceNotifications,
  WorkplaceNotificationsMap,
} from "../../constants/facility";
import { intersection, pick } from "lodash";
import { LDFlagSet } from "launchdarkly-react-client-sdk";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags";
import { TypeAlertSettings } from "@src/modules/myAccount/types";
import { isDefined } from "@clipboard-health/util-ts";

export interface FacilityNotificationAlertType {
  default: {
    enabled: boolean;
    batch: boolean;
    notifyRole?: string;
    userModifiableBatch: boolean;
    userModifiableState: boolean;
    tempBatchDisable?: boolean;
    time: number;
  };
  disableBatching?: boolean;
  type: ALERT_TYPE;
  title: string;
  action: string;
  role?: string;
  mandatoryRoles?: string[];
  mandatoryPermissions?: string[];
  validRoles?: string[];
  validPermissions?: string[];
  lddFlag?: string;
}

export interface FacilityNotification {
  action: string;
  alertTypes?: FacilityNotificationAlertType[];
  type: ALERT_TYPE;
  default: FacilityNotificationAlertType["default"];
}

export type FacilityFlags = Record<CbhFeatureFlag, string | boolean | number | unknown | any>;

const addAllToList = (
  values: Record<ALERT_TYPE, FacilityNotification[]>,
  userGranularSettings: Partial<TypeAlertSettings> | undefined,
  type: ALERT_TYPE
): FacilityNotificationAlertType | boolean => {
  if (
    userGranularSettings?.[type]?.["All"]?.userModifiableState !== undefined &&
    userGranularSettings[type]["All"].userModifiableBatch !== undefined &&
    values[type].length > 0
  ) {
    const val = userGranularSettings[type]["All"];
    return {
      default: {
        tempBatchDisable: val.tempBatchDisable,
        userModifiableState: val.userModifiableState,
        userModifiableBatch: val.userModifiableBatch,
        enabled: val.enabled,
        batch: val.batch,
        time: val.time,
      },
      type,
      title: "All",
      action: "All",
    };
  }
  const addAll = values[type].length > 0;
  const enableNotDisabled = values[type].some((value) => value.default.userModifiableState);
  const showBatchEnable = values[type].some((value) => value.default.userModifiableBatch);

  const batchState =
    values[type].length === 1
      ? values[type][0].default.batch
      : values[type].every((item) => {
          if (item.default.batch === false && item.default.userModifiableBatch === false) {
            return true;
          }
          return item.default.batch;
        });

  const onOffState =
    values[type].length === 1
      ? values[type][0].default.enabled
      : values[type].every((item) => {
          if (item.default.enabled === false && item.default.userModifiableState === false) {
            return true;
          }
          return item.default.enabled;
        });

  if (addAll) {
    return {
      default: {
        userModifiableState: enableNotDisabled,
        userModifiableBatch: showBatchEnable,
        enabled: onOffState,
        batch: batchState,
        time: 16,
      },
      type,
      title: "All",
      action: "All",
    };
  }
  return false;
};

const filterFacilityNotificationByRolesAndFlags = (
  facilityNotifications: WorkplaceNotificationsMap,
  roles: string[] | undefined,
  flags?: LDFlagSet
): WorkplaceNotificationsMap => {
  const values = Object.entries(facilityNotifications).map(([configKey, config]) => {
    const configValues = Object.entries(config)
      .map(([subConfigKey, subConfig]) => {
        // Skip the whole preference in case the Launch Darkly flag is OFF.
        if (subConfig.lddFlag && !flags?.[subConfig.lddFlag]) {
          return undefined;
        }
        if (
          subConfigKey !== CHARGE_RATE_VISIBLE_EMAIL ||
          intersection(roles, [FACILITY_ROLES.ADMIN, FACILITY_ROLES.INVOICES]).length === 0
        ) {
          return [subConfigKey, subConfig] as const;
        }

        return undefined;
      })
      .filter((subConfig) => isDefined(subConfig));
    return [configKey, Object.fromEntries(configValues)] as const;
  });
  return Object.fromEntries(values) as WorkplaceNotificationsMap;
};

function getAlertTypeDefaultFromAuthorization(
  originalNotification: FacilityNotificationAlertType,
  roles: string[],
  permissions: string[]
): Partial<FacilityNotificationAlertType["default"]> {
  const notification: Partial<FacilityNotificationAlertType["default"]> = {};

  if (originalNotification.disableBatching) {
    notification["batch"] = false;
    notification["userModifiableBatch"] = false;
  }

  if (originalNotification?.mandatoryRoles?.length) {
    if (
      intersection(originalNotification.mandatoryRoles, roles).length ||
      intersection(originalNotification.mandatoryPermissions, permissions).length
    ) {
      notification["batch"] = false;
      notification["userModifiableBatch"] = false;
      notification["userModifiableState"] = false;
      notification["enabled"] = true;
    } else {
      notification["userModifiableState"] = originalNotification.default.userModifiableState;
    }
  }

  return notification;
}

function isAlertType(key: string): key is ALERT_TYPE {
  return Object.values(ALERT_TYPE).includes(key as ALERT_TYPE);
}

function overrideFacilityNotificationsAlertSettings(
  data: WorkplaceNotificationsMap,
  userGranularSettings: Partial<TypeAlertSettings> | undefined,
  roles: string[] | undefined,
  useDefaultEnabledState: boolean,
  flags?: LDFlagSet,
  permissions?: string[]
): Record<ALERT_TYPE, FacilityNotification[]> {
  const notificationEntries = Object.entries(data).map<[ALERT_TYPE, FacilityNotification[]]>(
    ([alertType, config]) => {
      if (!isAlertType(alertType)) {
        throw new Error(`Invalid alert type: ${alertType}`);
      }

      const alerts = Object.entries(config)
        .map(([_role, item]) => item.alertTypes)
        .flat()
        .filter((notification) => {
          if (notification?.lddFlag && !flags?.[notification.lddFlag]) {
            return false;
          }

          const hasRoles = intersection(notification?.validRoles, roles).length > 0;
          const hasPermissions =
            intersection(notification.validPermissions, permissions).length > 0;
          return hasRoles || hasPermissions;
        })
        .map((notificationDefaults) => {
          const notificationWithPermissionApplied = getAlertTypeDefaultFromAuthorization(
            notificationDefaults,
            roles ?? [],
            permissions ?? []
          );

          const notificationUserConfig =
            userGranularSettings?.[notificationDefaults.type]?.[notificationDefaults.action];
          if (!notificationUserConfig) {
            return {
              ...notificationDefaults,
              // this is called default but it's actually not the default. it's the current setting that will show on the UI
              default: {
                ...notificationDefaults.default,
                ...notificationWithPermissionApplied,
                enabled: useDefaultEnabledState
                  ? notificationWithPermissionApplied["enabled"] ??
                    notificationDefaults.default["enabled"]
                  : false,
              },
            };
          }

          return {
            ...notificationDefaults,
            default: {
              // NOTE:: Do not add `enable` and `batch` parameters to below list.
              ...pick(notificationDefaults.default, [
                "userModifiableState",
                "userModifiableBatch",
                "disableBatching",
                "tempBatchDisable",
                "time",
              ]),
              ...notificationUserConfig,
              ...notificationWithPermissionApplied,
            },
          };
        });

      return [alertType, alerts];
    }
  );

  return Object.fromEntries(notificationEntries) as Record<ALERT_TYPE, FacilityNotification[]>;
}

export const fillDefaultAlertSettings = (
  userGranularSettings: Partial<TypeAlertSettings> | undefined,
  roles: string[] | undefined,
  useDefaultEnabledState: boolean, // we pass true while saving new alert settings while modifying a user's permissions, and false when reading the user's current alert settings
  flags?: LDFlagSet,
  permissions?: string[]
) => {
  const filteredFacilityNotification = filterFacilityNotificationByRolesAndFlags(
    getDefaultWorkplaceNotifications(),
    roles,
    flags
  );

  const notifyValues = overrideFacilityNotificationsAlertSettings(
    filteredFacilityNotification,
    userGranularSettings,
    roles,
    useDefaultEnabledState,
    flags,
    permissions
  );

  const addAlltoEmail = addAllToList(notifyValues, userGranularSettings, ALERT_TYPE.EMAIL);
  const addAlltoSMS = addAllToList(notifyValues, userGranularSettings, ALERT_TYPE.SMS);

  if (typeof addAlltoEmail === "object" && addAlltoEmail) {
    notifyValues.EMAIL = [{ ...addAlltoEmail }, ...notifyValues.EMAIL];
  }

  if (typeof addAlltoSMS === "object" && addAlltoSMS) {
    notifyValues.SMS = [{ ...addAlltoSMS }, ...notifyValues.SMS];
  }

  return notifyValues;
};
