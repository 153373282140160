export const HCFExclusionReasons = {
  Attendance_Issues: "Attendance Issues (e.g. NCNS, Last Minute Cancels)",
  Timeliness_Issues: "Timeliness Issue (e.g. Arriving Late, Leaving Early)",
  Mistreatment_Patients_Staff:
    "Mistreatment of Patients and/or Staff (e.g. Rude, Accusatory, Violent)",
  Lacking_Professionalism_Training:
    "Lacking Professionalism & Training (e.g. Refusing Tasks, Sleeping, Mistakes)",
  Other: "Other, Only Select if Other Options Don’t Apply",
};

export const DEFAULT_DNR_REASONS = {
  reasons: [
    {
      dnrCharacterFloor: 1,
      label: "No show or last-minute cancellation",
      value: HCFExclusionReasons.Attendance_Issues,
    },
    {
      dnrCharacterFloor: 1,
      label: "Arrived late and/or left early",
      value: HCFExclusionReasons.Timeliness_Issues,
    },
    {
      dnrCharacterFloor: 1,
      label: "Mistreatment of patients and/or staff",
      value: HCFExclusionReasons.Mistreatment_Patients_Staff,
    },
    {
      dnrCharacterFloor: 1,
      label: "Lacking professionalism & training",
      value: HCFExclusionReasons.Lacking_Professionalism_Training,
    },
    { dnrCharacterFloor: 1, label: "Other", value: HCFExclusionReasons.Other },
  ],
};
