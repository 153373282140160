import React from "react";
import { TextField, Box } from "@mui/material";
import { Text } from "@clipboard-health/ui-react";
import { textFieldStyles } from "./dnrFormStyles";

interface DnrFeedbackFieldProps {
  additionalFeedback: string;
  onFeedbackChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formSubmitted: boolean;
  feedbackError: boolean;
  localMinFeedbackLength: number;
  feedbackRequiredMessage: string;
}

/**
 * Component for the DNR feedback text field
 */
export const DnrFeedbackField: React.FC<DnrFeedbackFieldProps> = ({
  additionalFeedback,
  onFeedbackChange,
  formSubmitted,
  feedbackError,
  localMinFeedbackLength,
  feedbackRequiredMessage,
}) => {
  return (
    <TextField
      multiline
      rows={4}
      fullWidth
      value={additionalFeedback}
      onChange={onFeedbackChange}
      placeholder="Additional feedback"
      sx={textFieldStyles}
      helperText={
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <span>
            {formSubmitted &&
            localMinFeedbackLength > 0 &&
            additionalFeedback.length < localMinFeedbackLength
              ? feedbackRequiredMessage
              : ""}
          </span>
          <span>
            <Text variant="body2">{additionalFeedback.length}</Text>
          </span>
        </Box>
      }
      error={
        formSubmitted &&
        (feedbackError ||
          (localMinFeedbackLength > 0 && additionalFeedback.length < localMinFeedbackLength))
      }
    />
  );
};
